<script>
import searchBar from '@components/searchBar'
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import Vue from "vue";
import PaginationRow from "@components/global/PaginationRow";


export default {
  components: {PaginationRow, searchBar},
  mixins: [PaginatedDataMixin],
  props:{
      athleteView: {
          default: false,
          type: Boolean
      },
      athleteId:{
          default: null,
          type: String
      }
  },

  computed:{
    extraParams(){

    }
  },

  data() {
    return {
      dietPlans: [],
      queryParams: {
          includeSystem: true,
          includeUser: true,
        searchDropdown: 0,
          searchKeyword: '',
          timer: null,

          isTextOnly: 'all'
      },
      dpTags: [],
      showTagList: false,
      isCreatingPlan: false,
      isTextOnlyOptions: [
        {value: 'all', text: 'Προβολή όλων'},
        {value: 'false', text: 'Εξατομικευμένα διατροφικά πλάνα'},
        {value: 'true', text: 'Ελεύθερα διατροφικά πλάνα'}
      ],
    }
  },

  async created(){

      // initialize Mixin data for the querying of this particular API.
      this.api.url = '/dietPlan/';
      this.api.query.limit = 5; // 5 per page
      Vue.set(this.api.filters, 'tagId', null);
      Vue.set(this.api.filters, 'athleteId', null);
      Vue.set(this.api.filters, 'isTextOnly', 'all');
      Vue.set(this.api.filters, 'includeSystem', true);
      Vue.set(this.api.filters, 'includeUser', true);

      if(this.athleteView) this.api.filters.athleteId = this.athleteId;

      await this.fetchDPTags();
      this.dpTags = this.$store.state.tags._dietPlanTags;
      if(!this.dpTags){
          this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των ετικετών απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
      }

      this.getData();


  },
  methods: {

      async fetchDPTags(){
          if(!this.$store.state.tags._dietPlanTags) {
              await this.$store.dispatch('tags/fetchCacheDietPlanTags');
          }else{
              await this.$store.dispatch('tags/refreshDPTagsCache');
          }
      },

      filterSearchRadius(){
          if(this.queryParams.searchDropdown === '0'){
              this.api.filters.includeSystem = true;
              this.api.filters.includeUser = true;
          }
          if(this.queryParams.searchDropdown === '1'){
              this.api.filters.includeSystem = true;
              this.api.filters.includeUser = false;
          }
          if(this.queryParams.searchDropdown === '2'){
              this.api.filters.includeSystem = false;
              this.api.filters.includeUser = true;
          }
          this.api.page = 1; // change page to 1 (removes offset by default)
          this.getData();
      },

      redirectToPlan(record){
          if(this.athleteView) this.$router.push(`/athletes/${this.athleteId}/diet-plan/${record.id}`);
          else this.$router.push(`/diet-plans/${record.id}`);
      },

      redirectById(id){
          if(this.athleteView) this.$router.push(`/athletes/${this.athleteId}/diet-plan/${id}`);
          else this.$router.push(`/diet-plans/${id}`);
      },

      createNewDietPlanModal(){
        this.$refs['create-diet-plan'].show();
      },

      createNewDietPlan(isTextOnly){
          if(this.isCreatingPlan) return;
          this.isCreatingPlan = true;
          let params = {};
          if(this.athleteView) params.athleteId = this.athleteId;
          if(isTextOnly){
            params.isTextOnly = true;
          }
          this.$axios.post(`/dietPlan/`, params).then(result=>{
              this.redirectById(result.data.planId);
          }).catch(e=>{
            this.isCreatingPlan = false;
              this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: 'Αποτυχία!', text: 'Η δημιουργία διατροφικού πλάνου απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
          });
      },

      fetchPlansByTag(tagId){
          this.api.filters.tagId = tagId;
          this.getData();
      },

      deleteDietPlan(dpId){
          let message = {
              title: 'Θέλετε σίγουρα να διαγράψετε το διατροφικό πλάνο;',
              body: 'Η ενέργεια είναι μη αναστρέψιμη. Εάν πατήσετε διαγραφή, δεν θα μπορέσετε να επαναφέρετε αυτό το διατροφικό πλάνο.'
          };
          this.$dialog.confirm(message, {
              loader: true,
              okText: 'Διαγραφή',
              cancelText: 'Ακύρωση',
              backdropClose: true
          }).then((dialog) => {
              this.$axios.delete(`/dietPlan/${dpId}`).then(result => {
                  dialog.close();
                  this.$notify({group: 'athlisis-notifications', type: 'success', title: this.$t('success.title'), text: 'Το διατροφικό πλάνο διαγράφηκε επιτυχώς!'});
                  this.getData();

              }).catch(e => {
                  this.$notify({group: 'athlisis-notifications', type: 'error', title: this.$t('errors.title'), text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
              });
          }).catch(()=>{});
      },

  }
}

</script>
<style lang="scss">
@import '@src/design/custom/pages/_dietPlanList.scss';
.card-body{
    padding:1rem;
}

</style>
<template>
<div id="dp-list-container">
  <searchBar>
      <template v-slot:forms>
          <form class="form-inline ">
              <div class="form-group mt-2"><label class="sr-only">{{ $t('search') }}</label>
                  <b-form-input v-model="api.filters.search" type="search" class="form-control" :placeholder="$t('searchPlaceholder')" debounce="400" />
              </div>
              <div class="form-group mx-sm-3 mt-2">
                  <label for="status-select" class="mr-2">Προβολή:</label>
                  <select id="status-select" v-model="queryParams.searchDropdown" class="custom-select" @change="filterSearchRadius">
                      <option value='0'>Προβολή όλων</option>
                      <option value='1'>Πρότυπα συστήματος</option>
                      <option value='2'>Προσωπικά πρότυπα</option>
                  </select>
              </div>
              <div class="form-group mt-2">
                <label for="text-only-select" class="mr-2">Επιλέξτε είδος:</label>
                <b-form-select id="text-only-select" v-model="api.filters.isTextOnly" :options="isTextOnlyOptions"
                               @change="getData"></b-form-select>
              </div>
              <div class="form-group d-block ml-2 mt-2">
                  <b-button v-if="dpTags && dpTags.length > 0" variant="light" @click="showTagList = !showTagList">Φιλτράρισμα ανά ετικέτα <i v-if="!showTagList" class="fe-arrow-down"></i> <i v-if="showTagList" class="fe-arrow-up"></i></b-button>
              </div>
          </form>
      </template>
      <template v-slot:buttons>
          <div class="text-lg-right mt-3 mt-lg-0">
              <b-dropdown id="dropdown-dropleft" dropleft variant="light" class="mr-1">
                  <template v-slot:button-content>
                      <i class="fe-settings"></i>
                  </template>
                  <b-dropdown-text><h5 style="font-weight:800;"><i class="fe-settings"></i> Επιλογές:</h5> </b-dropdown-text>
                  <b-dropdown-item @click="$router.push({ name: 'user-tags' })"><i class="fe-tag"></i> Επεξεργασία ετικετών</b-dropdown-item>
                  <b-dropdown-item @click="$router.push({ name: 'meals' })"><i class="fas fa-swatchbook"></i> Πρότυπα γεύματα</b-dropdown-item>
                  <b-dropdown-item @click="$router.push({ name: 'print-templates' })"><i class="icon-printer"></i> Πρότυπα εκτύπωσης</b-dropdown-item>
                  <b-dropdown-item @click="$router.push({ name: 'diet-plan-notes' })"><i class="icon-notebook"></i> Πρότυπα σημειώσεων</b-dropdown-item>
                  <b-dropdown-item @click="$router.push({ name: 'recipes' })"><i class="icon-book-open"></i> Συνταγές</b-dropdown-item>
              </b-dropdown>
              <button type="button" class="btn btn-primary mr-1" :disabled="isCreatingPlan" @click="createNewDietPlanModal"><i class="fe-plus-circle"></i> Προσθήκη νέου πλάνου</button>
          </div>
      </template>
  </searchBar>

  <div v-if="showTagList" class="choose-from-tags row">
      <div class="col-md-12">
          <div class="card">
              <div class="card-body">
                  <b-button variant="outline-primary" class="tag-btn" :class=" (null === api.filters.tagId) ? 'active-tag-btn' : '' "
                            @click="fetchPlansByTag(null)">Προβολή όλων</b-button>
                  <span v-for="tag in dpTags" :key="tag.id">
                      <b-button variant="outline-primary" class="tag-btn" :class=" (tag.id === api.filters.tagId) ? 'active-tag-btn' : '' "
                                @click="fetchPlansByTag(tag.id)"><i class="fe-tag"></i> {{tag.text}}</b-button>
                  </span>
              </div>
          </div>
      </div>
  </div>
  <div v-if="api.rows.length === 0 && !api.filters.tagId && !api.loading && api.filters.search === '' && api.page === 1">
      <div class="card dp-not-found">
          <div class="card-body text-center">
              <h2 class="headline-not-found">ΔΕΝ ΒΡΕΘΗΚΑΝ ΔΙΑΤΡΟΦΙΚΑ ΠΛΑΝΑ</h2>
              <p>Δεν έχετε δημιουργήσει ακόμα κάποιο διατροφικό πλάνο<span v-if="athleteView"> σε αυτόν τον πελάτη</span>.</p>
              <b-button variant="primary" @click="createNewDietPlanModal"><i class="fe-plus-circle"></i> ΠΡΟΣΘΗΚΗ ΔΙΑΤΡΟΦΙΚΟΥ ΠΛΑΝΟΥ</b-button>
              <div class="pt-3"><img src="@assets/images/undraw-empty.svg" class="img-elements-not-found" alt="" height="250"/></div>

          </div>
      </div>
  </div>

  <div v-if="api.rows.length > 0 " class="row">
      <div class="col-md-12">
          <div  v-for="dp in api.rows" :key="dp.id">
              <b-card  class="shadow-sm diet-plan-item" :class="dp.isSystem ? 'diet-plan-item-system' : ''" @click="redirectToPlan(dp)">
                  <b-card-text>
                      <div class="row">
                          <div class="col-md-2">
                              <span class="text-muted">Όνομα</span>  <br>
                              <span class="dp-name">
                                  <span v-if="dp.isSystem" class="text-muted system-dp-label">
                                       Πρότυπο συστήματος<br>
                                  </span>
                                  <span v-if="dp.isTextOnly" class="text-muted system-dp-label" style="border-radius:6px">
                                         Ελεύθερο πλάνο (μόνο κείμενο)<br>
                                    </span>
                                  {{dp.name}}</span>
                          </div>
                          <div class="col-md-2">
                              <span class="text-muted">Ετικέτες</span>  <br>
                              <div v-if="dp.dpTags.length > 0" class="tag-container">
                                  <div v-for="tag in dp.dpTags" :key="tag.id" class="ti-tag ti-valid">
                                      <div class="ti-content">
                                          <div class="ti-tag-center">
                                              {{tag.text}}
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div v-else>-</div>
                          </div>
                          <div v-if="!dp.isTextOnly" class="col-md-3">
                              <span class="text-muted">Ιδιότητες</span>  <br>
                              <img v-if="dp.isVegan" v-b-tooltip.hover src="@assets/images/icons/icon-vegan.png" width="35px" alt="icon-vegan" title="Vegan διατροφολόγιο"/>

                              <img v-if="dp.isFasting" v-b-tooltip.hover src="@assets/images/icons/icon-fasting.png" width="35px" alt="icon-vegan" title="Νηστίσιμο διατροφολόγιο"/>

                              <img v-if="dp.isCarbohydration" v-b-tooltip.hover src="@assets/images/icons/icon-carbohydration.png" width="35px" alt="icon-vegan" title="Υδατανθράκωση"/>

                              <img v-if="dp.isKeto" v-b-tooltip.hover src="@assets/images/icons/keto-icon.png" width="35px" alt="icon-vegan" title="Κέτωση"/>
                          </div>
                        <div v-else class="col-md-3">

                        </div>
                          <div class="col-md-2">
                              <span class="text-muted">Τελευταία τροποποίηση</span>  <br>
                              <span class="dp-name"> {{formatDate(dp.updatedAt)}}</span>
                          </div>
                          <div class="col-md-2">
                              <span class="text-muted">Ημερομηνία δημιουργίας</span>  <br>
                              <span class="dp-name">{{formatDate(dp.createdAt)}}</span>
                          </div>
                          <div class="col-md-1 ">
                              <b-button variant="primary" class="btn-xs align-middle m-1"><i class="fe-eye"></i></b-button>
                              <b-button v-show="!dp.isSystem" variant="danger" class="btn-xs align-middle m-1" @click.stop="deleteDietPlan(dp.id)"><i class="icon-trash"></i></b-button>
                          </div>
                      </div>


                  </b-card-text>
              </b-card>
          </div>

        <pagination-row :api="api" @fetchData="getData"></pagination-row>
      </div>
  </div>
  <b-modal ref="create-diet-plan" id="create-diet-plan" centered  hide-footer hide-header>
    <div class="row text-center p-2">
      <div class="col-12 mb-3">
        <h4 class="text-primary">Τι είδους διατροφικό πλάνο θέλετε να δημιουργήσετε;</h4>
      </div>
      <div class="col-6">
        <b-button variant="outline-primary" class="btn-block h-100" @click="createNewDietPlan(false)">
          <i class="icon-pie-chart"  style="font-size:26px;"></i>
          <p class="font-weight-bold mt-2 mb-0">Εξατομικευμένο πλάνο</p>
          <span style="font-size:0.8em;opacity:0.8;">
            (Διατροφικό πλάνο με εξισώσεις, τρόφιμα, αναλύσεις κ.λπ.)
          </span>
        </b-button>
      </div>
      <div class="col-6">
        <b-button variant="outline-primary" class="btn-block h-100" @click="createNewDietPlan(true)">
          <i class="icon-notebook" style="font-size:26px;"></i>
          <p class="font-weight-bold mt-2 mb-0">Ελεύθερο πλάνο</p>
          <span style="font-size:0.8em;opacity:0.8;">
            (Διατροφικό πλάνο μόνο με κείμενο)
          </span>
        </b-button>
      </div>
    </div>
  </b-modal>
</div>
</template>
