import { render, staticRenderFns } from "./PhotoSlider.vue?vue&type=template&id=42227bf4&"
import script from "./PhotoSlider.vue?vue&type=script&lang=js&"
export * from "./PhotoSlider.vue?vue&type=script&lang=js&"
import style0 from "./PhotoSlider.vue?vue&type=style&index=0&id=42227bf4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.0_f3ovp5syquuyqzyjytk6i7vpxq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports