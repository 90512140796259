<template>

  <div class="print-latest-part print-text-center" style="position:absolute; bottom:10px;left:0;right:0;">
    <div class="print-regular-text" :style="`color:${colors.primary}`">
      {{getFooterLine}}
    </div>
  </div>


</template>


<script>

export default{

  props:{
    user:Object,
    colors: Object
  },

  computed:{

    getFooterLine(){

      let row = this.user.firstName + ' '+ this.user.lastName;

      if(!this.isEmpty(this.user.userCompany.occupation)) row += '  | ' + this.user.userCompany.occupation;
      if(!this.isEmpty(this.user.userCompany.telephone) || !this.isEmpty(this.user.userCompany.mobile)){
        row += ' | ' + this.user.userCompany.telephone + ' ' + this.user.userCompany.mobile
      }
      return row;
    }
  },
  methods:{
    isEmpty(value){
      try{
        return !value || value === '';
      }catch(e){
        return true;
      }

    }
  }


}
</script>