<template>

  <div v-if="!isEducational" id="athlete-files">

    <div class="row text-right">
      <div class="col-md-6">
        <b-form-input type="search" v-model="api.filters.search" debounce="400" :placeholder="$t('searchPlaceholder')"/>
      </div>
      <div class="col-md-6">
        <b-button variant="primary" @click="showDropZone = !showDropZone">Μεταφόρτωση αρχείου</b-button>

      </div>
    </div>

    <div class="row mb-4">
      <Div class="col-md-12">
        <vue-dropzone v-show="showDropZone" id="dropzone" ref="uploadLogo" :options="dropzoneOptions"
                      @vdropzone-success="uploadSuccess"
                      @vdropzone-error="uploadError"
                      class="mt-3 mb-3"></vue-dropzone>
        </div>
    </div>

    <div v-if="api.rows.length > 0" class="row">


      <b-card v-for="file in api.rows" :key="file.id" class="shadow-sm col-md-6 mb-1">
          <b-card-text>
            <div class="row ">
              <div class="col-md-1 align-self-center">
                <span class="file-icon">
                         <i :class="`fa ${getFileTypeIcon(file)}`"/>
                </span>

              </div>
              <div class="col-md-4">
                <span class="small-size">Όνομα:</span>

                <span v-if="editingId !== file.id" class="val">{{ file.name }}</span>

                <span class="edit-name float-right"
                      :class="editingId === file.id ? 'active-edit-name' : ''" @click="editingId === file.id? editingId = null : editingId = file.id">
                                    <i class="icon-pencil"></i>
                </span>

                <span v-if="editingId === file.id" class="small-size edit-name-form">
                   <b-form-input v-model="file.name" class="small-size edit-name-form" @change="updateFileName(file)"></b-form-input>
                  </span>
              </div>
              <div class="col-md-2">
                <span class="small-size">Ημερομηνία:</span> {{ formatDate(file.createdAt)}}
              </div>
              <div class="col-md-2">
                <span class="small-size">Μέγεθος:</span> {{ formatBytes(file.size) }}
              </div>
              <div class="col-md-3 text-md-center">
                <b-button variant="primary" class="mr-2 " @click="viewFile(file.id)"><i class="fa fa-eye" /></b-button>
                <b-button variant="danger" @click="deleteFile(file.id)"><i class="fa fa-trash" /></b-button>
              </div>
            </div>

          </b-card-text>
        </b-card>
    </div>

    <div v-else-if="api.filters.search.length === 0" class="row">
      <div class="col-md-12">
        <b-card class="text-center">
          <img src="@assets/images/undraw-empty.svg" class="img-elements-not-found" alt="" height="250"/>
          <div class="text-center">
            <h3 class="mt-4">Δεν έχετε ανεβάσει ακόμα αρχεία στον πελάτη!</h3>
          </div>
        </b-card>

      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12">
        <b-card>
          <div class="text-center">
            <p>Δεν βρέθηκαν αρχεία με το συγκεκριμένο όνομα...</p>
          </div>
        </b-card>

      </div>
    </div>

    <div class="row mt-5">
      <div v-if="totalFileSize >= 0" class="col-md-3 mt-3">
        Χωρητικότητα δίσκου λογαριασμού:
        <b-progress v-b-tooltip :max="fileSizeLimit" animated :title="formatBytes(totalFileSize) + ' από '+ formatBytes(fileSizeLimit)" class="mt-2 mb-2 ">
          <b-progress-bar :value="totalFileSize"  :label="formatBytes(totalFileSize) + ' από '+ formatBytes(fileSizeLimit)" show-progress
                          :variant="getProgressVariant()"></b-progress-bar>
        </b-progress>
      </div>

      <pagination-row :api="api" @fetchData="getData"></pagination-row>

    </div>
  </div>
  <educational-unavailable v-else/>
</template>

<style lang="scss">
#athlete-files{
  .file-icon{
    background:#f3f3f3;
    padding:10px 12px;
    border-radius:99px;

  }
  .edit-name-form{
    width:90%;
  }
  .active-edit-name{
    color:white;
    background-color:#3155A6;
    padding: 3px 8px;
    border-radius:90%;
    /*font-size:0.7rem;*/
  }
}
</style>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import PaginationRow from "@components/global/PaginationRow";


export default{
  props:{
    athlete: Object
  },
  mixins: [PaginatedDataMixin],
  components: {PaginationRow, vueDropzone: vue2Dropzone},
  data(){
    return{

      showDropZone: false,
      files: [],
      isLoaded: false,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API}/general/files/${this.athlete.id}/file-upload`,
        thumbnailWidth: 150,
        headers: { "x-access-token": JSON.parse(window.localStorage.getItem('auth.token')) },
        maxFiles: 1,
        dictDefaultMessage: 'Σύρτε εδώ ένα αρχείο ή πατήστε πάνω στο πλαίσιο (Μέγιστο μέγεθος 10MB)',
        init: function() {
          this.on("complete", function(file) {
            this.removeFile(file);
          });
        },
      },
      editingId: null,
      totalFileSize: null,
      fileSizeLimit: 2000000000

    }
  },
  created(){
    this.api.url = `/general/files/${this.athlete.id}/list`;
    this.api.extraTrackedFields = {
      totalFileSize: 'userStorage',
      fileSizeLimit: 'maxStorage'
    }
    this.getData();
  },

  methods:{

    updateFileName(file){

      this.$axios.put(`/general/files/${file.id}/rename`,
          {name: file.name}).then(res => {
        this.$notify({group: 'athlisis-notifications', type:'success',title: 'Επιτυχία!', text: 'Το όνομα του αρχείου αποθηκεύτηκε επιτυχώς!'});
            this.editingId = null;
      }).catch(e=>{});
    },
    uploadSuccess(){
      this.api.page = 1;
      this.showDropZone=false;
      this.getData();
      this.$notify({group: 'athlisis-notifications', type:'success',title: 'Επιτυχία!', text: 'Το αρχείο αποθηκεύτηκε επιτυχώς!'});
    },
    uploadError(){
      this.$notify({group: 'athlisis-notifications', type:'error',
        duration:5000, title: 'Κάτι πήγε λάθος!', text: 'Η μεταφόρτωση του αρχείου απέτυχε!'});

    },

    getProgressVariant(){
      if(this.totalFileSize > this.fileSizeLimit){
        return 'danger';
      }
      if(this.totalFileSize <= this.fileSizeLimit * 0.8){
        return 'success';
      }

      return 'warning'
    },
    viewFile(fileId){
      this.$axios.get(`/general/files/${fileId}`).then(res=>{

        let url = res.data;
        window.open(url, "_blank");
      }).catch(e => {});
    },

    deleteFile(fileId){
      let message = {
        title: 'Θέλετε σίγουρα να διαγράψετε το αρχείο;',
        body: 'Η ενέργεια είναι μη αναστρέψιμη. Εάν πατήσετε διαγραφή, δεν θα μπορέσετε να επαναφέρετε αυτό το αρχείο.'
      };

      this.$dialog.confirm(message, {
        loader: true,
        okText: 'Διαγραφή',
        cancelText: 'Ακύρωση',
      }).then((dialog) => {
        this.$axios.delete(`/general/files/${fileId}`).then(res => {

          this.getData();
          dialog.close();
        }).catch(e => {
        });
      }).catch(()=>{});
    },
    getFileTypeIcon(file){

      if(file.name.endsWith('.jpg') || file.name.endsWith('.png') || file.name.endsWith('.jpeg')){
        return 'fa-file-image';
      }
      if(file.name.endsWith('.pdf')){
        return 'fa-file-pdf';
      }
      if(file.name.endsWith('.doc') || file.name.endsWith('.docx')){
        return 'fa-file-word'
      }
      if(file.name.endsWith('.xls') || file.name.endsWith('xlsx')){
        return 'fa-file-excel';
      }
      if(file.name.endsWith('.ppt') || file.name.endsWith('.pptx')){
        return 'fa-file-powerpoint';
      }
      return 'fa-file';
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      const k = 1000;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  }
}
</script>
