<template>

  <div class="row">
    <div class="col-md-12">

      <b-tabs>
        <b-tab title="Προβολή μετρήσεων" style="max-height:60vh;overflow-y:scroll;overflow-x:hidden">
          <p>
            Αλλάζοντας τις ρυθμίσες αυτές, θα σταματήσουν να εμφανίζονται οι μετρήσεις που έχετε από-επιλέξει.<br> Δεν θα εμφανίζονται ούτε στην μαζική πρόοδο των μετρήσεων,
            ούτε στη μαζική εισαγωγή.<br>
            <i>Σημείωση: Δεν θα χαθούν μετρήσεις που έχετε ήδη εισάγει!</i>
          </p>
          <div v-for="(category, index) of measurementCategories" :key="'category-' + category" :title="measurementCategoryTitles[index]">

            <h5>{{measurementCategoryTitles[index]}}</h5>
            <b-form-checkbox-group v-model="displayedMeasurements" name="some-radios" class="multiselect-measurements">
              <b-form-checkbox v-for="item in measurements.filter(x => x.type === category)" :key="item.id" :value="item.id" style="width:45%;">
                {{item.name}}</b-form-checkbox>
            </b-form-checkbox-group>
            <hr>
          </div>
        </b-tab>


        <save-button @click="saveDisplayedMeasurements"></save-button>
      </b-tabs>

   </div>
  </div>
</template>


<script>
import axios from 'axios'
export default{

  props:{
    measurements: Array,
    displayedMeasurements: Array,
    measurementCategories: Array,
    measurementCategoryTitles: Array
  },

  methods:{
    saveDisplayedMeasurements(){
      axios.put(`${process.env.VUE_APP_API}/users/displayed-measurements`, {displayedMeasurements: this.displayedMeasurements}).then(res => {
        this.$emit('displayedMeasurementChanged');
        this.$notify({group: 'athlisis-notifications', type:'success',title: 'Επιτυχία!', text: 'Οι ρυθμίσεις αποθηκεύτηκαν επιτυχώς!'});
      }).catch(e =>{
        console.log(e);
        this.$notify({group: 'athlisis-notifications', type:'error', title: 'Κάτι πήγε λάθος!', text: 'Η αποθήκευση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
      });
    }
  }
}
</script>
