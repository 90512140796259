<template>
    <div id="photo-slider-wrapper" class="photo-slider-wrapper" tabindex="-1" @keydown.esc="close" @keydown.left="prevSlide" @keydown.right="nextSlide" @click="close">
        <div class="close-slider">
            <b-button variant="light" @click="close">Κλείσιμο</b-button>
        </div>
        <div class="photo-slider">
            <div v-for="(img, photoIndex) in photos" :key="photoIndex" class="align-self-center" @click.stop>
                <img :src="img.src" width="auto" class="align-self-center slide-image" :style="photoIndex === currentSlide ? 'display:block' : 'display:none;'"/>
            </div>
        </div>


    </div>

</template>

<script>

    export default {

        props:{
            photos:{
                type: Array,
                default: () => { return []}
            },
            index: {
                type: Number,
                default: 0
            }
        },

        data(){
            return {
                currentSlide: this.index
            }
        },

        mounted() {
            document.getElementById('photo-slider-wrapper').focus();
        },

        methods:{
            close(){
                this.$emit('close-slider');
            },

            prevSlide(){
                if(this.currentSlide-1 < 0){
                    return;
                }

                this.currentSlide--;

            },

            nextSlide(){
                if( this.currentSlide+1 === this.photos.length){
                   return;
                }
                this.currentSlide++;
            }
        }

    }

</script>


<style lang="scss">
    #photo-slider-wrapper{
        position:fixed;
        top:0;
        right:0;
        bottom:0;
        left: 0;
        z-index:9999;
        background-color: rgba(0,0,0,.8);
        display: flex;
        justify-content: center;
        align-items: center;

        .photo-slider{
            display: flex;
            justify-content: center;
            height:100vh;
        }
        .slide-image{
            max-height:800px;
            @media(max-width: 480px){
                max-width:300px;
            }
        }
        .close-slider{
            position: fixed;
            top:10px;
            right:30px;
        }
    }
</style>
