<template>
  <div class="heading-part">

    <div class="print-grid print-grid-cols-2 print-items-center print-bottom-distancing">
      <div style="display:flex;align-items:center;">
        <img :src="user.logo" alt="" style="max-height:75px;max-width:150px;flex-shrink:0;margin-right:5px;"/>
      </div>

      <div class="print-rounded print-paddings" style="text-align: center;" :style="`background-color:${colors.light};`">

        <div class="print-grid print-grid-cols-2">
          <div>
            <div class="print-small-title" :style="`color:${colors.primary};`">Ονοματεπώνυμο</div>
            <div class="print-regular-text">
              {{ athlete.firstName }} {{athlete.lastName}}
            </div>
          </div>
          <div>
            <div class="print-small-title" :style="`color:${colors.primary};`">Ημερομηνία</div>
            <div class="print-regular-text">
              {{formatDate(new Date())}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default{

  props:{
    athlete: Object,
    user: Object,
    colors: Object
  }

}
</script>