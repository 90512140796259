<script>
    import AthleteGoals from "./athleteGoals";
    import DatePicker from "vue2-datepicker";
    import VueTagsInput from "@johmun/vue-tags-input";
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import { quillEditor } from 'vue-quill-editor'
    import athleteEvents from "@views/athletes/athlete/events/athleteEvents";

    export default{

        components:{
            AthleteGoals, DatePicker, VueTagsInput, quillEditor, athleteEvents
        },

        props:{
            athlete: {
                default: () => {
                    return {}
                },
                type: Object
            },
            tags: {
                default: () => {
                    return []
                },
                type: Array
            },
            autocompleteItems: {
                default: () => {
                    return []
                },
                type: Array
            }
        },
        data(){
            return{
                startingFormState: null,
                currentFormState: null,
                isBtnDisabled: true,
                tag: '',
                validation: [{
                    classes: 'min-length',
                    rule: tag => tag.text.length < 2,
                }],
            }
        },
        computed: {
            filteredItems() {
                return this.autocompleteItems.filter(i => {
                    return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
                });
            },
        },

        created() {
            this.startingFormState = Object.values(this.athlete).reduce((a, b) => a+b);
        },

        beforeDestroy() {
            if(!this.isBtnDisabled){
                this.updateProfile();
            }
        },

        methods:{

            updateProfile(){
                this.athlete.birthDate = new Date(this.athlete.birthDate).setHours(12);
                this.athlete.email = this.athlete.email.replaceAll(' ', '').toLowerCase();

                this.$axios.put(`/athlete/${this.athlete.id}`, this.athlete).then(result =>{

                    this.$notify({group: 'athlisis-notifications', type:'success', title: this.$t('success.title'), text: 'Ο πελάτης ενημερώθηκε επιτυχώς!'});
                    this.$emit('update:athlete', result.data.athlete);
                    this.startingFormState = Object.values(this.athlete).reduce((a, b) => a+b);
                    this.isBtnDisabled = true;
                });
            },
            getAge(birthDate){
                return Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10);
            },

            checkForDifferences: function(){
                this.currentFormState = Object.values(this.athlete).reduce((a, b) => a+b);
                this.isBtnDisabled = this.currentFormState === this.startingFormState;
            },

            deleteTag(obj){
                this.$axios.delete(`/athlete/${this.athlete.id}/tag/${obj.tag.tagId}`).then(result => {
                    this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Η ετικέτα διαγράφηκε επιτυχώς!'});
                    this.tags.splice(obj.index, 1);
                }).catch( e  => {
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },

            addTag(obj){
                this.$axios.post(`/athlete/${this.athlete.id}/tag`, {text: obj.tag.text}).then(result => {
                    this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Η ετικέτα προστέθηκε επιτυχώς!'});
                    result.data.text = obj.tag.text;
                    this.tag='';
                    this.tags.push(result.data);
                    this.$store.dispatch('tags/fetchCacheAthleteTags'); // re-fetch and store data!

                }).catch(e=>{
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η προσθήκη της ετικέτας απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },

            deleteAthlete(){
                let message = {
                    title: 'ΠΡΟΣΟΧΗ! Θέλετε σίγουρα να διαγράψετε τον πελάτη;',
                    body: 'Η διαγραφή του πελάτη είναι οριστική και δεν μπορεί να μετακληθεί. Θα διαγραφούν οι μετρήσεις, τα διατροφικά πλάνα, τα ερωτηματολόγια, το φύλλο παρακολούθησης καθώς και το ασκησιολόγιο.'
                };
                this.$dialog.confirm(message, {
                    loader: true,
                    okText: 'Διαγραφή εγγραφής',
                    cancelText: 'Ακύρωση',
                    backdropClose: true
                }).then((dialog) => {
                    this.$axios.delete(`/athlete/${this.athlete.id}`).then(() => {
                        dialog.close();
                        this.$router.push('/athletes');
                    }).catch(e => {
                        dialog.close();
                        this.$notify({
                            group: 'athlisis-notifications',
                            type: 'error',
                            title: this.$t('errors.title'),
                            text: 'Η διαγραφή του πελάτη απέτυχε.'
                        });
                        }
                    )
                });
            },

          showAthleteEvents(){
            this.$refs["athlete-events"].show()
          }

        }
    }
</script>

<template>
    <div class="row">
        <div class="col-12 col-xl-5">
            <div class="card">
              <div class="row">
                <div class="col-6">
                  <b-button variant="outline-primary" size="xs" @click="showAthleteEvents">
                    Ιστορικότητα ραντεβού</b-button>
                </div>
                <div class="col-6 text-right">
                  <span id="delete-client">
                        <b-button variant="danger" size="xs" @click="deleteAthlete"
                                                                     :disabled="!!athlete.mobileClientId"><i class="fa fa-trash"></i></b-button>
                  </span>

                  <b-tooltip target="delete-client">{{ athlete.mobileClientId ? 'Πρέπει πρώτα να αποσυνδέσετε το mobile app' : 'Διαγραφή πελάτη' }}</b-tooltip>
                </div>
              </div>

                <div class="card-body">


                    <div class="pt-2 text-center">
                      <img v-if="athlete" class="rounded-circle img-thumbnail avatar-xl align-self-center"
                           style="width:100px;height:100px;" :src="athlete.image || 'https://www.gravatar.com/avatar/1db7993cb4a4a74ce58f4a6926cec867?s=80&d=mp&r=g'"/>

                        <h3>{{athlete.firstName}} {{athlete.lastName}}</h3>
                        <p><strong>Ηλικία:</strong> {{getAge(athlete.birthDate)}} ετών</p>
                    </div>
                    <p>Ετικέτες:
                        <vue-tags-input
                                v-model="tag"
                                :tags="tags"
                                :delete-on-backspace="false"
                                :autocomplete-items="filteredItems"
                                placeholder="Προσθήκη..."
                                :validation="validation"
                                @before-deleting-tag="deleteTag"
                                @before-adding-tag="addTag">
                            <template slot="autocomplete-header">
                                <strong>Επιλέξτε μια υπάρχουσα ετικέτα ↓</strong>
                            </template>
                        </vue-tags-input>

                    </p>
                </div>

            </div>
          <AthleteGoals></AthleteGoals>


        </div>
        <div class="col-12 col-xl-7">
            <div class="card">
                <div class="card-body">

                    <b-form @submit.prevent="updateProfile" @input="checkForDifferences">
                        <div class="row pt-3">
                            <div class="col-md-6">
                                <b-form-group id="input-firstName" label="Όνομα*" label-for="firstName" >
                                    <b-form-input id="firstName" v-model="athlete.firstName" type="text" placeholder="Εισάγετε το όνομα του πελάτη" required></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-6">
                                <b-form-group id="input-lastName" label="Επώνυμο*" label-for="lastName">
                                    <b-form-input id="lastName" v-model="athlete.lastName" type="text" placeholder="Εισάγετε το επώνυμο του πελάτη" required></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <b-form-group id="input-birthDate" label="Ημερομηνία Γέννησης" label-for="birthDate">
                                    <date-picker v-model="athlete.birthDate" :first-day-of-week="1" :lang="elDatePicker" format="DD-MM-YYYY" @change="isBtnDisabled = false"></date-picker>
                                </b-form-group>
                            </div>

                            <div class="col-md-4">
                                <b-form-group id="input-gender" label="Φύλο*" label-for="gender" >
                                    <b-form-select v-model="athlete.sex" required @change="checkForDifferences">
                                        <option value="null">Επιλέξτε φύλο: </option>
                                        <option value="M">Άρρεν</option>
                                        <option value="F">Θήλυ</option>
                                    </b-form-select>
                                </b-form-group>
                            </div>
                          <div class="col-md-4">
                            <label>Κόστος συνεδρίας:</label>
                            <b-input-group append="€" >
                              <b-form-input type="number" step="1" v-model="athlete.sessionCost" placeholder="(π.χ. 50)"/>
                            </b-input-group>
                          </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <b-form-group id="input-email" label="e-mail" label-for="email" >
                                    <b-form-input id="email" v-model="athlete.email" type="text" @change="athlete.email = athlete.email.replaceAll(' ', '').toLowerCase()"></b-form-input>
                                    <b-alert :show="athlete.mobileClientId !== null" variant="warning">Προσοχή. Έχετε συνδέσει την mobile εφαρμογή με αυτό το e-mail.</b-alert>
                                </b-form-group>
                            </div>
                            <div class="col-md-4">
                                <b-form-group id="input-mobileNum" label="Κινητό τηλέφωνο" label-for="mobileNum">
                                    <b-form-input id="mobileNum" v-model="athlete.mobileNum" type="text"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4">
                                <b-form-group id="input-phoneNum" label="Σταθερό τηλέφωνο" label-for="phoneNum" >
                                    <b-form-input id="phoneNum" v-model="athlete.phoneNum" type="text"></b-form-input>
                                </b-form-group>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <b-form-group id="input-address" label="Διεύθυνση" label-for="address">
                                    <b-form-input id="address" v-model="athlete.address" type="text"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4">
                                <b-form-group id="input-city" label="Πόλη" label-for="city" >
                                    <b-form-input id="city" v-model="athlete.city" type="text"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4">
                                <b-form-group id="input-zipCode" label="Ταχυδρομικός Κώδικας" label-for="zipCode" >
                                    <b-form-input id="zipCode" v-model="athlete.zipCode" type="text"></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                        <b-form-group id="input-notes" label="Σημειώσεις (προαιρετικό)" label-for="notes">
                          <quill-editor
                              ref="noteEditor"
                              v-model="athlete.notes"
                              :options="quillOptions" @change="isBtnDisabled = false"/>
                            </b-form-group>
                        <b-form-group id="button-group" class="mt-2 text-right">

                          <save-button type="submit" :disabled="isBtnDisabled"></save-button>

                        </b-form-group>
                    </b-form>
                </div>
            </div>
        </div>

      <b-modal ref="athlete-events" id="athlete-events" title="Ιστορικό ραντεβού" hide-footer>
        <div class="p-2">
          <athlete-events :athlete-id="athlete.id"/>
        </div>

      </b-modal>
    </div>
</template>
