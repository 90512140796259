<script>
import DatePicker from 'vue2-datepicker'
import moment from 'moment'
import athleteMeasurementsChart from "./measurements/athleteMeasurementsChart";
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import PaginationRow from "@components/global/PaginationRow";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {getSavedState, localStorageKeys, saveState} from "@utils/util";

export default {
  components: {PaginationRow, DatePicker, athleteMeasurementsChart, quillEditor },
  mixins: [PaginatedDataMixin],
  props:{
      athlete: {
          default: () => { return {}},
          type: Object
      }
  },
  data() {
    return {
      newProgressList: {
          week: 0,
          date: new Date(),
          description: '',
      },
      toggledProgressLists: [],
      currentProgressList: {},
      addingProgress: false,
      updatedFields: false,
      toggleAll:getSavedState(localStorageKeys.progressListToggle) || false,
      weightMeasurements: [],
      fatMeasurements: []
    }
  },

  created(){
    this.api.url = `/athlete/${this.athlete.id}/progress`;
    this.api.query.limit = 5;
      this.getData().then(()=>{

        try{
          this.toggledProgressLists.push(this.api.rows[0].id);

        }catch(e){}

      });
    this.getMeasurementsById(2);
    this.getMeasurementsById(3);
  },
  methods: {

    getMeasurementsById(id){
      this.$axios.get(`/athlete/${this.athlete.id}/measurements/${id}`).then(result => {
        if(id===2) this.weightMeasurements = result.data;
        else if(id===3) this.fatMeasurements = result.data;
      });
    },
    calculateChange(type){
      let start;
      let end;
      if(type==='fat'){
        if(!this.fatMeasurements.length >0) return 0;
        start = this.fatMeasurements[0].value;
        end = this.fatMeasurements[this.fatMeasurements.length -1].value;
      }else if(type==='weight'){
        if(!this.weightMeasurements.length >0) return 0;
        start = this.weightMeasurements[0].value;
        end = this.weightMeasurements[this.weightMeasurements.length -1].value;
      }
      let increase = end - start;
      return (increase / start * 100).toFixed(2);
    },
    calculateAbsoluteChange(type){
      let start;
      let end;
      if(type==='fat'){
        if(!this.fatMeasurements.length >0) return 0;
        start = this.fatMeasurements[0].value;
        end = this.fatMeasurements[this.fatMeasurements.length -1].value;
      }else if(type==='weight'){
        if(!this.weightMeasurements.length >0) return 0;
        start = this.weightMeasurements[0].value;
        end = this.weightMeasurements[this.weightMeasurements.length -1].value;
      }
      return (end - start).toFixed(2);
    },

    saveToLocalStorage(val){

        saveState(localStorageKeys.progressListToggle, val);
      },
      toggleProgressList(item){

        if(this.toggleAll){
          return this.openEditProgressModal(item);
        }
        if(this.toggledProgressLists.includes(item.id)){
          this.toggledProgressLists = this.toggledProgressLists.filter(x => x !== item.id)
        }else{
          this.toggledProgressLists.push(item.id);
        }
      },
      formatDate(date){
          return moment(date).format('DD/MM/YYYY');
      },
      openEditProgressModal(item){
          this.updatedFields = false;
          this.currentProgressList = item;
          this.$refs['update-progress-list'].show();
      },
      openAddProgressModal(){
          this.$refs['add-progress-list'].show();
      },
      addProgress(){
          this.addingProgress = true;
          this.$axios.post(`/athlete/${this.athlete.id}/progress`, {progressListItem: this.newProgressList}).then(result => {
              this.addingProgress = false;
              this.api.rows.unshift(result.data);
              this.api.count = this.api.count + 1;
              this.$refs['add-progress-list'].hide()
          }).catch(e =>{
              this.addingProgress = false;
              this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Έχει γίνει κάποια λάθος εισαγωγή στα δεδομένα, ή κάτι πήγε λάθος με τον εξυπηρετητή. Προσπαθήστε ξανά!'});
          });
      },

      updateProgress(){
          if(!this.updatedFields) return;
          this.updatedFields = false;
          this.addingProgress = true;
          this.$axios.put(`/athlete/${this.athlete.id}/progress/${this.currentProgressList.id}`, this.currentProgressList).then(result => {
              this.addingProgress = false;
              this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Η ενημέρωση ολοκλήρωθηκε επιτυχώς!'});
              this.$refs['update-progress-list'].hide()
          }).catch(e =>{
              this.addingProgress = false;
              this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Έχει γίνει κάποια λάθος εισαγωγή στα δεδομένα, ή κάτι πήγε λάθος με τον εξυπηρετητή. Προσπαθήστε ξανά!'});

              console.log(e);
          });
      },

      deleteProgressListItem(item){
          let message = {
              title: 'Θέλετε σίγουρα να διαγράψετε την εγγραφή;',
              body: 'Προσοχή. Θα διαγραφούν μαζί και οι μετρήσεις που έχουν εισαχθεί στον πελάτη μέσω του φύλλου παρακολούθησης.'
          };


          this.$dialog.confirm(message, {
              loader: true,
              okText: 'Διαγραφή εγγραφής',
              cancelText: 'Ακύρωση',
              backdropClose: true
          }).then((dialog) => {

              this.$axios.delete(`/athlete/${this.athlete.id}/progress/${item.id}`).then(() => {
                  dialog.close();
                  this.getData();
              }).catch(e => {
                  dialog.close();
                  this.$notify({
                      group: 'athlisis-notifications',
                      type: 'error',
                      duration: 5000,
                      title: this.$t('errors.title'),
                      text: 'Έχει γίνει κάποια λάθος εισαγωγή στα δεδομένα, ή κάτι πήγε λάθος με τον εξυπηρετητή. Προσπαθήστε ξανά!'
                  });

                  console.log(e);
              });
          });

      },
  },
}
</script>

<template>
<div id="athlete-progress-list">

  <div v-if="!api.loading && api.rows.length === 0" class="row">
    <div class="card col-md-6">
      <div class="card-body">
        <p>{{$t('pages.progress.noData')}}</p>
        <b-button variant="primary" @click="openAddProgressModal">{{ $t('create') }}</b-button>
      </div>
    </div>

  </div>

  <div v-else class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-9">
          <h4>{{ $t('pages.progress.title') }}</h4>
          <b-form-checkbox v-model="toggleAll" switch @change="saveToLocalStorage">{{ $t('pages.progress.openAll') }}</b-form-checkbox>

        </div>
        <div class="col-md-3 text-right">
          <b-button variant="primary" @click="openAddProgressModal">{{ $t('create') }}</b-button>
        </div>
      </div>

      <div class="progress-wrapper row pt-3">

        <div class="col-12 col-md-7">
          <div v-for="item in api.rows" :key="item.id"
               class="mb-2 progress-list-item py-1 px-3 ease-in-out duration-300 bg-white"
               style="border-radius:20px"
               :class="toggleAll || toggledProgressLists.includes(item.id)  ? 'py-2 px-4 shadow-sm' : ''">


            <div class="progress-list-item-header cursor-pointer py-2 grid grid-cols-2 gap-5 align-items-center header" @click.self="toggleProgressList(item)">

              <div class="d-flex align-items-center" @click="toggleProgressList(item)">
                <span class="ease-in-out duration-300 mr-3" :class="toggleAll || toggledProgressLists.includes(item.id) ? 'icon-arrow-down' :'icon-arrow-right'"></span>
                <div>
                  <label class="text-muted">{{ $t('date') }}</label>
                  <h4 class="mt-0"> {{formatDate(item.date)}}</h4>
                </div>
              </div>
              <div class="text-right actions">
                <b-button size="sm" variant="warning" class="mr-2" @click="openEditProgressModal(item)">
                  <i class="icon-pencil"></i>
                </b-button>

                <b-button size="sm" variant="danger" @click="deleteProgressListItem(item)">
                  <i class="icon-trash"></i>
                </b-button>
              </div>
            </div>
            <div v-if="toggleAll || toggledProgressLists.includes(item.id)" v-html="item.description" class="text-dark border-top py-3" style="font-size:1rem;"></div>
          </div>

          <pagination-row :api="api" @fetchData="getData"></pagination-row>
        </div>

        <div class="col-12 col-md-5">
          <b-card style="position:sticky;top:100px;">

              <div class="row text-center">
                    <div class="col-md-6 p-1">
                        <b-alert :variant="parseFloat(calculateChange('weight')) > 0 ? 'danger' : 'success'" show>
                          <p class="font-weight-bold mb-0">Μεταβολή βάρους </p>
                          <p class="text-sm">(από την 1η συνεδρία)</p>
                          <div class="percentage-change">
                            <span v-if="parseFloat(calculateChange('weight')) > 0" class="icon-arrow-up"></span> <span v-else class="icon-arrow-down"></span>
                          </div>
                          <p>
                            Απόλυτη μεταβολή: {{calculateAbsoluteChange('weight')}} kg <br>
                            Ποσοστιαία μεταβολή: {{calculateChange('weight')}} %
                          </p>

                        </b-alert>
                    </div>
                  <div class="col-md-6 p-1">
                      <b-alert :variant="parseFloat(calculateChange('fat')) > 0 ? 'danger' : 'success'" show>
                          <p class="font-weight-bold mb-0">Μεταβολή λίπους </p>
                        <p class="text-sm">(από την 1η συνεδρία)</p>
                        <div class="percentage-change">
                           <span v-if="parseFloat(calculateChange('fat')) > 0" class="icon-arrow-up"></span> <span v-else class="icon-arrow-down"></span>
                        </div>
                        <p>
                          Απόλυτη μεταβολή: {{calculateAbsoluteChange('fat')}}% <br>
                        Ποσοστιαία μεταβολή: {{calculateChange('fat')}} %
                        </p>

                      </b-alert>
                  </div>
              </div>


              <h5 class="bg-light casual-rounded p-2">Βάρος:</h5>
              <athlete-measurements-chart v-if="weightMeasurements.length > 0" :measurements="weightMeasurements"></athlete-measurements-chart>

              <h5 class="bg-light casual-rounded p-2">Λίπος:</h5>
              <athlete-measurements-chart v-if="fatMeasurements.length > 0"  :measurements="fatMeasurements"></athlete-measurements-chart>
          </b-card>

        </div>

      </div>
    </div>
  </div>

  <b-modal id="add-progress-list" ref="add-progress-list" title="Προσθήκη εγγραφής" size="xl" hide-footer>
    <b-overlay :show="addingProgress" rounded="sm">
      <b-form @submit.prevent="addProgress">
        <div class="row">
            <div class="col-md-12">
                <b-form-group id="date" label="Ημερομηνία συνεδρίας" label-for="date">
                    <date-picker v-model="newProgressList.date"  :first-day-of-week="1" :lang="elDatePicker" format="DD-MM-YYYY"></date-picker>
                </b-form-group>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <b-form-group id="description" label="Σημειώσεις για τη συνεδρία" label-for="description">
                  <quill-editor ref="new-progress-list-description" v-model="newProgressList.description" :options="quillOptions"/>
                </b-form-group>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-right">
                <b-button variant="primary" type="submit">Προσθήκη</b-button>
            </div>
        </div>
      </b-form>
    </b-overlay>
  </b-modal>

  <b-modal id="update-progress-list" ref="update-progress-list" hide-footer  size="xl"  title="Επεξεργασία εγγραφής" @hidden="updateProgress">
      <b-overlay :show="addingProgress" rounded="sm">
          <b-form @submit.prevent="updateProgress" @change="updatedFields = true">
              <div class="row">
                  <div class="col-md-12">
                      <b-form-group id="curDate" label="Ημερομηνία συνεδρίας" label-for="date">
                          <date-picker v-model="currentProgressList.date"  :first-day-of-week="1" :lang="elDatePicker" format="DD-MM-YYYY" @change="updatedFields = true"></date-picker>
                      </b-form-group>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12">
                      <b-form-group id="curDesc" label="Σημειώσεις για τη συνεδρία" label-for="description">
                        <quill-editor
                            ref="new-progress-list-description"
                            v-model="currentProgressList.description"
                            @change="updatedFields = true"
                            :options="quillOptions"/>
                       </b-form-group>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-12 text-right">
                    <save-button type="submit" :disabled="!updatedFields"></save-button>
                  </div>
              </div>
          </b-form>
      </b-overlay>
  </b-modal>
</div>



</template>
<style lang="scss">
#athlete-progress-list{

    .percentage-change{
        font-size:1.25rem;
    }
    tr{
        cursor:pointer;
    }
  .max-lines {
    display: block;/* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 75px;
    line-height: 1.8em;
  }
}

</style>
