<template>

  <div v-if="ranges.ranges.length > 0">
    <div style="width:100%;height:14px;background-color:gray;position:relative;"  :style="`background-color:${colors.backgrounds}`">


      <div style="position:absolute;bottom:-1px;z-index:4;width:2px;height:18px;border-radius:9px;"
           :style="`left: ${getCurrentMetricPercentageLeft}%;background-color:black;`"></div>


      <div v-for="tooltip in getTooltips"
           :key="`tooltip-${tooltip}`"
           style="position:absolute;bottom:-5px;font-size:4px;"
           :style="`left:calc(${ (tooltip-ranges.initialPoint) * ranges.multiplier}% - 0.125pt);`">

        {{tooltip}}

      </div>

      <div v-for="line in smallLines" class="lbsl" :style="`left: calc(${line*ranges.multiplier}% - 0.5px)`"></div>

      <div v-for="(range,index) of ranges.ranges" :key="`${measurement}-${index}`" style="position:absolute;bottom:0;height:14px;"
           :style="`left:${range.offset*ranges.multiplier}%;width:${range.percentage*ranges.multiplier}%;background-color:${range.color};`">
      </div>

    </div>
<!--
     ${index===0?'border-top-left-radius:12px;border-bottom-left-radius:12px;' :''}
           ${index === ranges.ranges.length -1 ? 'border-top-right-radius:12px;border-bottom-right-radius:12px;' :''}`"
           -->
<!--    {{value}}-->
  </div>
  <div v-else>
    -
  </div>
</template>


<script>
export default{


  props:{
    colors:Object,
    measurement: String,
    age: Number,
    isMale:{
      type: Boolean,
      default: false
    },
    height: Number,
    weight: Number,
    value: Number
  },


  // watch all props and re-calculate if needed!
  watch:{
    age(){
      this.ranges = this.getRanges;
    },
    weight(){
      this.ranges = this.getRanges;
    },
    isMale(){
      this.ranges = this.getRanges;
    },
    value(){
      this.ranges = this.getRanges;
    },
    measurement(){
      this.ranges = this.getRanges;
    }
  },


  computed:{

    // make sure we fit the limit 0-100 and not overflow lef-tright pos
    getCurrentMetricPercentageLeft(){
      let left = (this.getValue-this.ranges.initialPoint) * this.ranges.multiplier;
      if(left < 0) return 0;
      if(left > 100) return 100;
      return left;
    },
    getValue(){

      if(this.measurement === this.measurementTypes.tbw || this.measurement === this.measurementTypes.muscleMass){

        return Math.round(this.value / this.weight * 100);
      }

      return this.value
    },
    getTooltips(){
      return this.tooltips.filter( x => x>= this.ranges.initialPoint &&  x <=  (100 / this.ranges.multiplier) + this.ranges.initialPoint)
    },
    getRanges(){
      // weight
      if(this.measurement === this.measurementTypes.fatPer){
        return this.getFatRanges;
      }
      if(this.measurement === this.measurementTypes.organsFat){
        return this.getOrgansFatRanges;
      }
      if(this.measurement === this.measurementTypes.tbw){
        return this.getTBWRanges;
      }
      if(this.measurement === this.measurementTypes.muscleMass){
        return this.getMuscleMassRanges;
      }
      if(this.measurement === this.measurementTypes.bmi){
        return this.getBMIRanges;
      }
      return {initialPoint: 0, ranges: [], multiplier: 1}
    },


    getMuscleMassRanges(){

      if(this.isMale){

        if(this.age >= 18 && this.age <= 39){
          return {
            initialPoint: 60,
            multiplier: 2.5,
            ranges: [
              {offset: 0, percentage: 15, color: this.colorTypes.high}, // [0-7]%
              {offset: 15, percentage: 14, color: this.colorTypes.normal}, // (7,20]%
              {offset: 29, percentage: 11, color: this.colorTypes.high}, // (7,20]%
            ]
          }
        }
        if(this.age >= 40 && this.age <= 59){
          return {
            initialPoint: 60,
            multiplier: 2.5,
            ranges: [
              {offset: 0, percentage: 13, color: this.colorTypes.high}, // [0-7]%
              {offset: 13, percentage: 13, color: this.colorTypes.normal}, // (7,20]%
              {offset: 26, percentage: 14, color: this.colorTypes.high}, // (7,20]%
            ]
          }
        }
        if(this.age >= 60 && this.age <= 79){
          return {
            initialPoint: 60,
            multiplier: 2.5,
            ranges: [
              {offset: 0, percentage: 10, color: this.colorTypes.high}, // [0-7]%
              {offset: 10, percentage: 14, color: this.colorTypes.normal}, // (7,20]%
              {offset: 24, percentage: 16, color: this.colorTypes.high}, // (7,20]%
            ]
          }
        }
      }else{
        if(this.age >= 18 && this.age <= 39){

          return {
            initialPoint: 50,
            multiplier: 2.5,
            ranges: [
              {offset: 0, percentage: 13, color: this.colorTypes.high}, // [0-7]%
              {offset: 13, percentage: 12.5, color: this.colorTypes.normal}, // (7,20]%
              {offset: 25.5, percentage: 14.5, color: this.colorTypes.high}, // (7,20]%
            ]
          }
        }
        if(this.age >= 40 && this.age <= 59){

          return {
            initialPoint: 50,
            multiplier: 2.5,
            ranges: [
              {offset: 0, percentage: 12, color: this.colorTypes.high}, // [0-7]%
              {offset: 12, percentage: 11.5, color: this.colorTypes.normal}, // (7,20]%
              {offset: 23.5, percentage: 16.5, color: this.colorTypes.high}, // (7,20]%
            ]
          }
        }
        if(this.age >= 60 && this.age <= 79){

          return {
            initialPoint: 50,
            multiplier: 2.5,
            ranges: [
              {offset: 0, percentage: 10, color: this.colorTypes.high}, // [0-7]%
              {offset: 10, percentage: 12.5, color: this.colorTypes.normal}, // (7,20]%
              {offset: 22.5, percentage: 17.5, color: this.colorTypes.high}, // (7,20]%
            ]
          }
        }
      }
      return {initialPoint: 0, ranges: [], multiplier: 1}

    },

    getOrgansFatRanges(){

      return {
        initialPoint: 0,
        multiplier: 5,
        ranges: [
          {offset: 0, percentage: 13, color: this.colorTypes.normal}, // [0-7]%
          {offset: 13, percentage: 5, color: this.colorTypes.high}, // (7,20]%
          {offset: 18, percentage: 2, color: this.colorTypes.reallyHigh}, // (7,20]%
        ]
      }
    },
    getTBWRanges(){

      if(this.isMale){
        return {
          initialPoint: 40,
          multiplier: 3.33333,
          ranges: [
            {offset: 0, percentage: 10, color: this.colorTypes.high}, // [0-7]%
            {offset: 10, percentage: 15, color: this.colorTypes.normal}, // (7,20]%
            {offset: 25, percentage: 5, color: this.colorTypes.high}, // (7,20]%
          ]
        }
      }else{
        return {
          initialPoint: 40,
          multiplier: 3.33333,
          ranges: [
            {offset: 0, percentage: 5, color: this.colorTypes.high}, // [0-7]%
            {offset: 5, percentage: 15, color: this.colorTypes.normal}, // (7,20]%
            {offset: 20, percentage: 10, color: this.colorTypes.high}, // (7,20]%
          ]
        }
      }
    },

    // 0 - 45
    getBMIRanges(){
      return {
        initialPoint: 0,
        multiplier: 2.2222,
        ranges: [
          {offset: 0, percentage: 18.5, color: this.colorTypes.low},
          {offset: 18.5, percentage: (25-18.5), color: this.colorTypes.normal},
          {offset: 25, percentage: 5, color: this.colorTypes.low},
          {offset: 30, percentage: 5, color: '#f97316'},
          {offset: 35, percentage: 10, color: this.colorTypes.reallyHigh},
        ]
      }
    },
    getFatRanges(){
      // male
      if(this.isMale){

        if(this.age >= 18 && this.age <=39){
          return {
            initialPoint: 0,
            multiplier: 2,
            ranges: [
              {offset: 0, percentage: 7, color: this.colorTypes.low}, // [0-7]%
              {offset: 7, percentage: 13, color: this.colorTypes.normal}, // (7,20]%
              {offset: 20, percentage: 5,label: 21, color: this.colorTypes.high}, // (20, 25]%
              {offset: 25, percentage: 25,label: 26, color: this.colorTypes.reallyHigh} // (25, 100]%
            ]
          }
        }
        if(this.age >=40 && this.age <=59){
          return {
            initialPoint: 0,
            multiplier: 2,
            ranges: [
              {offset: 0, percentage: 10.5, color: this.colorTypes.low}, // [0-7]%
              {offset: 10.5, percentage: 11.5, color: this.colorTypes.normal}, // (7,20]%
              {offset: 22, percentage: (28.5-22), color: this.colorTypes.high}, // (20, 25]%
              {offset: 28.5, percentage: (50-28.5), color: this.colorTypes.reallyHigh} // (25, 100]%
            ]
          }
        }
        if(this.age >=60 && this.age <= 79){
          return {
            initialPoint: 0,
            multiplier: 2,
            ranges: [
              {offset: 0, percentage: 12, color: this.colorTypes.low}, // [0-7]%
              {offset: 12, percentage: 13, color: this.colorTypes.normal}, // (7,20]%
              {offset: 25, percentage: 5, color: this.colorTypes.high}, // (20, 25]%
              {offset: 30, percentage: (50-30), color: this.colorTypes.reallyHigh} // (25, 100]%
            ]
          }
        }
      }else {
        if (this.age >= 18 && this.age <= 39) {
          return {
            initialPoint: 0,
            multiplier: 2,
            ranges: [
              {offset: 0, percentage: 21, color: this.colorTypes.low}, // [0-7]%
              {offset: 21, percentage: 12, color: this.colorTypes.normal}, // (7,20]%
              {offset: 33, percentage: (39.5 - 33), color: this.colorTypes.high}, // (20, 25]%
              {offset: 39.5, percentage: 10.5, color: this.colorTypes.reallyHigh} // (25, 100]%
            ]
          }
        }

        if (this.age >= 40 && this.age <= 59) {
          return {
            initialPoint: 0,
            multiplier: 2,
            ranges: [
              {offset: 0, percentage: 23, label: 0, color: this.colorTypes.low}, // [0-7]%
              {offset: 23, percentage: 11, label: 23, color: this.colorTypes.normal}, // (7,20]%
              {offset: 34, percentage: 6, label: 34, color: this.colorTypes.high}, // (20, 25]%
              {offset: 40, percentage: 10, label: 40, color: this.colorTypes.reallyHigh} // (25, 100]%
            ]
          }
        }
        if (this.age >= 60 && this.age <= 79) {
          return {
            initialPoint: 0,
            multiplier: 2,
            ranges: [
              {offset: 0, percentage: 24, label: 0, color: this.colorTypes.low}, // [0-7]%
              {offset: 24, percentage: 12.5, label: 24, color: this.colorTypes.normal}, // (7,20]%
              {offset: 36.5, percentage: 5, label: 36.5, color: this.colorTypes.high}, // (20, 25]%
              {offset: 41.5, percentage: 8.5, label: 41.5, color: this.colorTypes.reallyHigh} // (25, 100]%
            ]
          }
        }
      }
      return {        initialPoint: 0,multiplier: 1, ranges: []};
    }
  },

  data(){
    return{
      tooltips: [0,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100],
      smallLines: [],
      measurementTypes:{
        weight: "weight",
        fatPer: "fatPer",
        fatKg: "fatKg",
        muscleMass: "muscleMass",
        organsFat: "organsFat",
        tbw: "tbw",
        bmi: "bmi"
      },
      colorTypes: {
        reallyHigh: '#d12026',        // red
        high: '#facc15',
        normal: '#4baa3e',
        low: '#facc15',
        highButGood: '#38a4db'
      },
      ranges: {multiplier: 1, ranges: []}
    }
  },

  mounted(){
    this.ranges = this.getRanges;

    if(this.ranges.ranges.length > 0 ){
      for(let i=1;i<(100 / this.ranges.multiplier);i++){
        this.smallLines.push(i);
      }
    }

  },
  methods:{

  }
}
</script>