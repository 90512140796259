<script>
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import Vue from 'vue'
    import DatePicker from 'vue2-datepicker'
    import moment from 'moment'
    /**
     * AthleteGoals component
     */
    export default {
        components: { VuePerfectScrollbar, DatePicker },
        data() {
            return {
                goalsData: [],
                goalList: {
                    description: '',
                    dueDate: new Date()
                },
                goalSubmitted: false,
                completedToDos: Array,
                athleteId: this.$route.params.athleteId
            }
        },

        computed: {
            /**
             * return completed todo
             */
            completedGoals() {
                return [...this.goalsData.filter(x => x.isCompleted===true)]
            },
            uncompletedGoals() {
                return [...this.goalsData.filter(x => x.isCompleted===false)]
            }
        },

        created(){
            this.goalList.dueDate.setMonth(this.goalList.dueDate.getMonth() + 1);
            this.$axios.get(`/athlete/${this.athleteId}/goals`).then(result=>{
                this.goalsData = result.data;
            }).catch(e=>{
                this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η φόρτωση των στόχων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
            });
        },
        methods: {
            /**
             * Todo form submit
             */
            saveGoal() {
                this.goalSubmitted = true;

               if(!this.goalList.description) return;
                const goal = this.goalList;

                this.$axios.post(`/athlete/${this.athleteId}/goals`, goal).then(result => {
                    this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Ο στόχος προστέθηκε επιτυχώς!'});
                    this.goalSubmitted= false;
                    this.goalList.description = '';
                    this.goalsData.unshift(result.data);
                }).catch(e=>{});
            },

            /**
             * Check box value change
             */
            updateCheck(goalId) {

                this.$axios.put(`/athlete/${this.athleteId}/goals/${goalId}`).then(result => {
                     let goal = this.goalsData.find(x => x.id === goalId)
                     goal.isCompleted = !goal.isCompleted
                    // this.goalsData = this.goalsData.map(x => { if(x.id === goalId){x.isCompleted = !x.isCompleted} return x});
                });

            },

            deleteGoal(goalId){
              this.$dialog.confirm({
                title:  this.$t('verifyDeletion.title'),
                body: this.$t('verifyDeletion.message')
              }, {
                html: true,
                loader: true,
                okText: this.$t('verifyDeletion.ok'),
                cancelText:  this.$t('verifyDeletion.cancel'),
                backdropClose: true
              }).then((dialog) => {
                this.$axios.delete(`/athlete/${this.athleteId}/goals/${goalId}`).then(result=>{
                  this.$notify({
                    group: 'athlisis-notifications', type: 'success',
                    title: this.$t('success.title'),
                    text: this.$t('success.text')
                  });
                  this.goalsData = this.goalsData.filter(x => x.id !== goalId);
                }).catch(e=>{
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'),
                      text: this.$t('errors.text')});
                  }).finally(()=>{
                    dialog.close();
                  });
              }).catch(()=>{});
            },
        },
    }
</script>
<style>
    .text-checked{
        text-decoration: line-through;
    }
  .list-group{
    min-height:300px;
  }
    .goalsapp .add-goal{
        height:100%;
        width:100%;
    }

</style>

<template>
    <div class="card p-3">
        <b-tabs justified content-class="mt-1" nav-class="nav-tabs nav-bordered">
            <b-tab title='Ανεκπλήρωτοι στόχοι' active>
                <div class="goalsapp">
                    <VuePerfectScrollbar>
                        <div style="min-height:165px;max-height: 165px">
                            <ul v-if="goalsData.length > 0" id="todo-list" class="list-group list-group-flush todo-list" >

                                <li v-for="(goal) in uncompletedGoals" :key="goal.id" class="list-group-item border-0 pl-1 checkbox checkbox-primary">
                                    <input :id="`${goal.id}`" :checked="`${goal.isCompleted ? true : ''}`" type="checkbox" @change="updateCheck(goal.id)"/>
                                    <label :for="`${goal.id}`"><span :class="`${goal.isCompleted ? 'text-checked' : ''}`"> {{goal.description}}<br> <small class="text-muted">Εκπλήρωση στόχου έως: {{formatDateWithoutHour(goal.dueDate)}}</small> </span></label>
                                    <b-button variant="light" class="float-right btn-xs" @click="deleteGoal(goal.id)"><i class="fe-trash"></i></b-button>
                                </li>
                            </ul>
                        </div>
                    </VuePerfectScrollbar>
                    <hr>
                    <form @submit.prevent="saveGoal">
                        <div class="row mt-2">
                            <div class="col-lg-6 todo-input">
                                <b-form-group id="input-goal" label="Στόχος: " label-for="goal">
                                    <input v-model="goalList.description" type="text" class="form-control " placeholder="Προσθήκη νέου στόχου" name="todo" :class="{ 'is-invalid': goalSubmitted && !goalList.description }"/>
                                    <div v-if="goalSubmitted && !goalList.description" class="invalid-feedback">Το πεδίο είναι απαραίτητο.</div>
                                </b-form-group>
                            </div>
                            <div class="col-lg-3 todo-date">
                                <b-form-group id="input-dueDate" label="Έως:" label-for="dueDate">
                                    <date-picker v-model="goalList.dueDate" :first-day-of-week="1" :lang="elDatePicker" format="DD-MM-YYYY"></date-picker>
                                </b-form-group>
                            </div>
                            <div class="col-lg-3 add-goal">
                                <b-form-group label="Προσθήκη" label-for="addGoal">
                                    <button type="submit" class="btn btn-primary add-goal"><i class="fe-plus"></i></button>
                                </b-form-group>
                            </div>


                        </div>
                        <!-- end row -->
                    </form>
                    <!-- end form -->
                </div>
            </b-tab>
            <b-tab title="Εκπληρωμένοι στόχοι">
                <div class="goalsapp">
                    <VuePerfectScrollbar v-if="goalsData.length > 0">
                        <div>
                            <ul id="completed-todo-list" class="list-group list-group-flush todo-list" style="max-height: 320px">

                                <li v-for="(goal) in completedGoals" :key="goal.id" class="list-group-item border-0 pl-1 checkbox checkbox-primary">
                                    <input :id="`${goal.id}`" :checked="`${goal.isCompleted ? true : ''}`" type="checkbox" @change="updateCheck(goal.id)"/>
                                    <label :for="`${goal.id}`"><span :class="`${goal.isCompleted ? 'text-checked' : ''}`"> {{goal.description}} <br>
                                        <small class="text-muted">Ημερομηνία ολοκλήρωσης: {{formatDateWithoutHour(goal.updatedAt)}} | Στόχος: {{formatDateWithoutHour(goal.dueDate)}}</small> </span></label>
                                    <b-button variant="light" class="float-right btn-xs" @click="deleteGoal(goal.id)"><i class="fe-trash"></i></b-button>
                                </li>
                            </ul>
                        </div>
                    </VuePerfectScrollbar>
                    <!-- end form -->
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>
