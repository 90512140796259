<template>

  <div class="print-measurements-wrapper">

    <div class="mx-auto">

      <div class="row">
        <div class="col-md-3">

          <b-button variant="primary" class="w-100" @click="printMeasurements('print')"
:disabled="printLoading"
          ><span class="icon-printer"></span> Εκτύπωση</b-button>
          <b-button variant="primary" class="w-100 mt-2" @click="printMeasurements('save')" :disabled="printLoading" ><span class="icon-printer"></span> Αποθήκευση ως PDF</b-button>


          <div class="mt-4">
            <label >Επιλογή χρώματος</label>
          </div>

          <div class="d-inline-block ">

            <div v-for="colorOption in colorTemplates" :key="colorOption.hex" class="d-inline-block mx-1">
              <div style="height:35px;width:35px;cursor:pointer;border-radius:100%" class="cursor-pointer" :style="`background-color:${colorOption.hex};
              ${colorOption.hex === color ? 'border: 4px solid black':''}`" @click="color = colorOption.hex"></div>
            </div>


          </div>

          <b-form-input v-model="color" @click="showColorPicker = !showColorPicker"></b-form-input>

          <ColorPicker v-show="showColorPicker" v-model="color" @colorChanged="val => { this.color = val.substring(0,7) }"
                       @closeModal="showColorPicker = false;"/>


          <b-alert variant="warning" show class="mt-3">
            <p>Η πρώτη έκδοση της εκτύπωσής μας για αποτελέσματα λιπομετρητή λειτουργεί με την τελευταία μέτρηση
            που υπάρχει καταχωρημένη στον πελάτη σας.</p> <p>Μελλοντικά, θα μπορείτε να επιλέξετε και πολλές μετρήσεις (2η σελίδα με διαγράμματα προόδου), καθώς και να
          εμφανίσετε την εκτύπωση για παλαιότερη ημερομηνία.</p> <p>Εάν αντιμετωπίζετε κάποιο πρόβλημα,
            επικοινωνήστε μαζί μας στο info@athlisis.com. Θα εκτιμηθεί να μας κάνετε αναλυτική αναφορά και να συνοδεύεται από εικόνες/βίντεο του προβλήματός σας.</p> </b-alert>
        </div>
        <div class="col-md-9" style="max-height:80vh;overflow-y:auto;">

          <b-alert v-if="!athleteHeight "  show variant="danger" dismissible>Προσοχή! Δεν έχετε εισάγει το <strong>ύψος</strong> του πελάτη σας.</b-alert>
          <b-alert v-if="!printingBaseResults[measurementsMapping.weight]"  dismissible show variant="danger">
            Προσοχή! Δεν έχετε εισάγει <strong>βάρος</strong> για τον πελάτη σας και η εκτύπωση δεν μπορεί να λειτουργήσει σωστά.</b-alert>

          <div v-if="isReadyToPrint" id="print-area" ref="print-area" class="a4 page" style="font-family:'Open Sans', sans-serif;position:relative;margin:0mm 0mm 0mm 0mm;"
               :style="`background-color:${colors.light}`">

            <div class="a4 page" style="position:relative;">

              <div class="decoration-top-left" style="position:absolute;top:0;left:0;" :style="`color:${colors.backgrounds}`" v-html="fatMeterPrintIcons.top_left"></div>


              <div class="decoration-bottom-right" style="max-width:unset;position:absolute;bottom:0;right:0;left:0;width:100%;"
                   :style="`color:${colors.backgrounds}`" v-html="fatMeterPrintIcons.bottom_right"></div>


              <div class="print-content-area " style="background:white;color:black;border-radius:22px;margin:auto;padding:30px 35px;">

                <fat-meter-heading :user="user" :colors="colors" :athlete="athlete"></fat-meter-heading>

                <div class="print-grid print-items-center">

                  <fat-meter-section-title title="ΠΛΗΡΟΦΟΡΙΕΣ" style="margin-bottom:6px;" :colors="colors"></fat-meter-section-title>

                  <div class="print-grid print-grid-cols-4 print-bottom-distancing">

                    <fat-meter-info-print-icon :icon="fatMeterPrintIcons.age"
                                               :colors="colors"
                                               label="Ηλικία"
                                               :value="getAge"></fat-meter-info-print-icon>

                    <fat-meter-info-print-icon :icon="fatMeterPrintIcons.sex"
                                               :colors="colors"
                                               label="Φύλο"
                                               :value="athlete.sex === 'M' ? 'Άρρεν' : 'Θήλυ'"></fat-meter-info-print-icon>

                    <fat-meter-info-print-icon :icon="fatMeterPrintIcons.height"
                                               :colors="colors"
                                               label="Ύψος"
                                               :value="athleteHeight + ' cm'"></fat-meter-info-print-icon>

                    <fat-meter-info-print-icon :icon="fatMeterPrintIcons.clothes"
                                               :colors="colors"
                                               label="Ρούχα"
                                               :value="printingBaseResults[measurementsMapping.clothesKg] + 'kg'"></fat-meter-info-print-icon>

                  </div>

                </div>

                <div class="apotelesmata-metrisewn " style="margin-bottom:10px;">

                  <fat-meter-section-title title="ΑΠΟΤΕΛΕΣΜΑΤΑ ΜΕΤΡΗΣΗΣ" :colors="colors" style="border-bottom-left-radius:0;border-bottom-right-radius:0"></fat-meter-section-title>
                  <div class="print-rounded print-paddings"
                       style="border-top-left-radius:0;border-top-right-radius:0;"
                       :style="`background-color:${colors.light};`">

                    <div style="width:100%;display:inline-block; margin-bottom:10px;font-weight:bold;">

                      <div style="width:20%;display:inline-block;" class="print-regular-text " :style="`color:${colors.primary}`">

                      </div>
                      <div style="width:20%;display:inline-block;" class="print-regular-text" :style="`color:${colors.primary}`">
                        Αποτελέσματα
                      </div>
                      <div style="width:20%;display:inline-block;" class="print-regular-text" :style="`color:${colors.primary}`">
                        Επιθυμητά
                      </div>
                      <div style="width:40%;display:inline-block;" class="print-regular-text" :style="`color:${colors.primary}`">
                        Φυσιολογικά όρια
                      </div>
                    </div>
                    <div style="width:100%">
                      <div  v-for="(data,index) in initialTableMeasurementsList" :key="index" style="width:100%;padding:2px 0;display:inline-block;">
                        <div style="width:20%;display:inline-block;" class="print-regular-text">{{ data.measurement }}</div>
                        <div style="width:20%;display:inline-block;" class="print-regular-text">

                          {{printingBaseResults[measurementsMapping[data.key]] + ' ' +  data.measure }}
                          {{data.key === "fatKg" ? ' / ' + printingBaseResults[measurementsMapping.fatPer] + '%' : ''}}

                          {{data.key === "tbw" ? ' / ' +  getTBWPercentage + '%' : ''}}

                          {{data.key === "muscleMass" ? ' / ' +  getMuscleMassPercentage + '%' : ''}}

                        </div>

                        <div style="width:20%;display:inline-block;" class="print-regular-text">{{data.desired}}</div>


                        <div style="width:40%;display:inline-block;" class="print-regular-text">

                          <fat-meter-limit-bar
                              v-if="data.key !== 'fatKg'"
                              :colors="colors"
                              :weight="printingBaseResults[measurementsMapping.weight]"
                              :age="getAgeNumber"
                              :is-male="athlete.sex === 'M'"
                              :measurement="data.key"
                              :height="athleteHeight"
                              :value="printingBaseResults[measurementsMapping[data.key]]"></fat-meter-limit-bar>

                          <fat-meter-limit-bar
                              v-else
                              :colors="colors"
                              :weight="printingBaseResults[measurementsMapping.weight]"
                              :age="getAgeNumber"
                              :is-male="athlete.sex === 'M'"
                              measurement="fatPer"
                              :height="athleteHeight"
                              :value="printingBaseResults[measurementsMapping.fatPer]"></fat-meter-limit-bar>
                        </div>

                      </div>

                      <div v-if="filteredExtraData.length > 0"  class="print-grid print-grid-cols-4" style="margin-top:10px;">
                        <div v-for="(data,index) in filteredExtraData" :key="`extra-${index}`" style="display:flex;justify-content:center;align-items:center;">
                          <div class="print-regular-text" style="flex-shrink:0;margin-right:12px;">
                            {{data.measurement}}
                          </div>
                          <div class="print-regular-text print-rounded print-text-center" style="background-color:white;padding:0 15px;width:45%">
                            {{printingBaseResults[measurementsMapping[data.key]] + ' ' +  data.measure}}

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>




                <div class="tmimatiki-analusi print-bottom-distancing">

                  <fat-meter-section-title title="ΤΜΗΜΑΤΙΚΗ ΑΝΑΛΥΣΗ" :colors="colors" style="margin-bottom:6px;"></fat-meter-section-title>
                  <div class="print-grid print-grid-cols-2">
                    <div>
                      <div class="print-text-center print-rounded print-large-text"
                           style="font-weight:bold;padding:5px;" :style="`background-color:${colors.light};color:${colors.primary}`">
                        Μύες
                      </div>

                      <div class="print-paddings">

                        <fat-meter-measurement label="ΚΟΡΜΟΣ" :value="printingBaseResults[measurementsMapping.muscleCoreKg] + ' kg'"></fat-meter-measurement>

                        <div class="print-grid print-grid-cols-3 " style="margin-top:12px;">
                          <fat-meter-body-side :hand="printingBaseResults[measurementsMapping.muscleLeftArmKg] + ' kg'"
                                               :leg="printingBaseResults[measurementsMapping.muscleLeftFootKg] + ' kg'"
                                               :colors="colors"></fat-meter-body-side>
                          <div>
                            <div class="print-icon"  v-html="fatMeterPrintIcons.body" style="margin-top:10px;" :style="`color:${colors.primary}`"></div>
                          </div>
                          <div>
                            <fat-meter-body-side
                                :hand="printingBaseResults[measurementsMapping.muscleRightArmKg] + ' kg'"
                                :leg="printingBaseResults[measurementsMapping.muscleRightFootKg] + ' kg'"
                                :is-left="false" :colors="colors"></fat-meter-body-side>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div>
                      <div class="print-text-center print-rounded print-large-text" style="font-weight:bold;padding:5px;" :style="`background-color:${colors.light};color:${colors.primary}`">
                        Λίπος
                      </div>
                      <div class="print-paddings">

                        <fat-meter-measurement label="ΚΟΡΜΟΣ"
                                               :value="printingBaseResults[measurementsMapping.fatCoreKg] + ' kg'">

                        </fat-meter-measurement>

                        <div class="print-grid print-grid-cols-3 " style="margin-top:12px;">
                          <fat-meter-body-side
                              :hand="printingBaseResults[measurementsMapping.fatLeftArmKg] + ' kg'"
                              :leg="printingBaseResults[measurementsMapping.fatRightFootKg] + ' kg'"
                              :colors="colors"></fat-meter-body-side>
                          <div>
                            <div class="print-icon"  v-html="fatMeterPrintIcons.body" style="margin-top:10px;" :style="`color:${colors.primary}`"></div>
                          </div>
                          <div>
                            <fat-meter-body-side
                                :hand="printingBaseResults[measurementsMapping.fatRightArmKg] + ' kg'"
                                :leg="printingBaseResults[measurementsMapping.fatRightFootKg] + ' kg'"
                                :is-left="false" :colors="colors"></fat-meter-body-side>
                          </div>
                        </div>

                      </div>
                    </div>


                  </div>
                </div>


                <div class="sistasi swmatos">
                  <fat-meter-section-title title="ΣΥΣΤΑΣΗ ΣΩΜΑΤΟΣ" :colors="colors" style="margin-bottom:6px;"></fat-meter-section-title>

                  <div class="print-grid print-grid-cols-3">
                    <fat-meter-weight-body :colors="colors"
                                           :weight="printingBaseResults[measurementsMapping.weight]  + ' kg'"></fat-meter-weight-body>

                    <fat-meter-mass-body :colors="colors"
                                         :fat-free-mass="printingBaseResults[measurementsMapping.fatlessMass]"
                                         :fat-mass="printingBaseResults[measurementsMapping.fatKg]">

                    </fat-meter-mass-body>

                    <fat-meter-water-body :colors="colors"
                                          :weight="printingBaseResults[measurementsMapping.weight]"
                                          :tbw="printingBaseResults[measurementsMapping.tbw]"></fat-meter-water-body>
                  </div>

                </div>


                <fat-meter-footer :user="user" :colors="colors"></fat-meter-footer>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>
    </div>

</template>

<style lang="scss">
.print-measurements-wrapper{
  .print-content-wrap{
    max-height:500px;
    overflow-y:scroll;
  }
}

@media print{
  body {margin: 2px;}
}

.lbsl{position:absolute;z-index:3;bottom:0;height:14px;width:0.1%;background-color:#f9fafb25;}
.pagebreak{page-break-before:always};

.decoration-bottom-right svg{
  width: 210mm;
  height: 142mm;
}
.decoration-top-left svg{
  width:100%!important;
  height:auto;
}
.print-grid{
  display:grid;
  gap:10px;
}
.print-grid-cols-2{
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.print-grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.print-grid-cols-4{
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.print-items-center{
  align-items:center;
}

.a4.page{
  position:relative;
  width: 210mm;
  height: 297mm;
  margin: 0;
  padding: 0;
  box-sizing:content-box;
}

.print-bottom-distancing{
  margin-bottom:18px;
}


.print-rounded{
  border-radius:12px;
}
.print-rounded-lg{
  border-radius:22px;
}
.print-paddings{
  padding:12px;
}
.print-text-center{
  text-align:center;
}
.print-title{
  font-size:16px;
  font-weight:600;
}
.print-icon svg{
  max-width:100%;
  margin:0 auto;
}
.print-content-area{
  position:absolute;
  top:7.5mm;
  left:7.5mm;
  right:7.5mm;
  bottom:7.5mm;
}
.print-small-title{
  font-weight:700;font-size:12.5px
}
.print-regular-text{
  font-size:11px;
}
.print-large-text{
  font-size:12.5px;
}
.print-measurement-label{
  font-weight:300;
  font-size:12px;
}
.print-measurement-result{
  font-size:18px;
  font-weight:600;
}

</style>


<script>
import Vue from "vue";
import { FormWizard, TabContent } from 'vue-form-wizard'
import PrintMixin from "@src/mixins/PrintMixin";
import fatMeterPrintIcons from "@assets/js/fat-meter-icons";
import FatMeterMeasurement from "@components/print/fat-meter/FatMeterMeasurement";
import FatMeterSectionTitle from "@components/print/fat-meter/FatMeterSectionTitle";
import FatMeterBodySide from "@components/print/fat-meter/FatMeterBodySide";
import FatMeterWeightBody from "@components/print/fat-meter/FatMeterWeightBody";
import FatMeterMassBody from "@components/print/fat-meter/FatMeterMassBody";
import FatMeterWaterBody from "@components/print/fat-meter/FatMeterWaterBody";
import moment from "moment";
import FatMeterInfoPrintIcon from "@components/print/fat-meter/FatMeterInfoPrintIcon";
import {mapState} from "vuex";
import FatMeterHeading from "@components/print/fat-meter/FatMeterHeading";
import FatMeterFooter from "@components/print/fat-meter/FatMeterFooter";
import FatMeterLimitBar from "@components/print/fat-meter/FatMeterLimitBar";
import ColorPicker from "@components/ColorPicker";

export default{

  props:{
    athlete:Object,
    measurements: Array,
    athleteMeasurements: Array
  },

  watch:{

    color(val){

      // only save valid colors.
      if(val && val.startsWith('#') && val.length === 7){
        localStorage.setItem('measurements.print.color', val);
      }

    }
  },

  computed:{


    templatesIncludesColor(){
      return this.colorTemplates.map(x => x.hex).includes(this.color)
    },

    ...mapState({
      user: state => state.auth.currentUser
    }),
    colors(){
      return {
        primary: this.color,
        backgrounds: this.color+'70',
        light: this.color+'15',
        weight: '#F6921E',
        fatFreeMass: '#EE9DBF',
        fatMass: '#30A56E',
        water: '#22B0E3'
      }

    },

    getTBWPercentage(){

      try{
        return Math.round(this.printingBaseResults[this.measurementsMapping.tbw] / this.printingBaseResults[this.measurementsMapping.weight] * 100)
      }catch(e){
        return '-';
      }
    },

    getMuscleMassPercentage(){
      try{
        return +(this.printingBaseResults[this.measurementsMapping.muscleMass] / this.printingBaseResults[this.measurementsMapping.weight] * 100).toFixed(1)
      }catch(e){
        return '-';
      }
    },

    initialTableMeasurementsList(){
      let data = [];
      for(let item of Object.values(this.basicArrayData)){
        if(this.printingBaseResults[this.measurementsMapping[item.key]]){
          data.push(item);
        }
      }
      return data;
    },
    filteredExtraData(){
      let data = [];
      for(let item of Object.values(this.singleRowExtraData)){
        if(this.printingBaseResults[this.measurementsMapping[item.key]]){
          data.push(item);
        }
      }
      return data;
    },

    getAgeNumber(){
      // return moment
      return moment().diff(this.athlete.birthDate, "years");
    },

    getAge(){
      return moment(this.athlete.birthDate, "YYYY-MM-DD HH:mm:ss").fromNow()
          .replace('months ago', 'Μηνών')
          .replace('years ago', 'Ετών')
          .replace('days ago', 'Ημερών')
          .replace('hours ago', 'Ωρών')
      // return moment().diff(this.athlete.birthDate, 'years', true);
    },
  },
  components: {
    ColorPicker,
    FatMeterLimitBar,
    FatMeterFooter,
    FatMeterHeading,
    FatMeterInfoPrintIcon,
    FatMeterWaterBody,
    FatMeterMassBody,
    FatMeterWeightBody, FatMeterBodySide, FatMeterSectionTitle, FatMeterMeasurement, FormWizard, TabContent},
  mixins: [PrintMixin],
  data(){
    return{
      showColorPicker: false,
      fatMeterPrintIcons,
      printOptions:{
        selectedMeasurements: [],
        showDiagrams: true,
        showText: true,
        maxMeasurements: 10,
        step:1
      },
      isPrinting: false,
      printableMeasurements: [],
      color: localStorage.hasOwnProperty('measurements.print.color') ? localStorage.getItem('measurements.print.color') : '#3155A6',

      colorTemplates: [
        {name: 'Default', hex: '#3155A6'},
        {name: 'Green', hex: '#16a34a'},
        {name: 'Green Dark I', hex: '#15803d'},
        {name: 'Red', hex: '#dc2626'},
        {name: 'Red Dark I', hex: '#b91c1c'},
        {name: 'Emerald',  hex: '#059669'},
        {name: 'Blue', hex: '#1d4ed8'},
        {name: 'Purple', hex: '#7e22ce'},
        {name: 'Fuschia', hex: '#c026d3'},
        {name: 'Pink', hex: '#db2777'},
        {name: 'Rose', hex: '#be123c'},

      ],
      measurementsMapping: {
        weight: 2,
        fatPer: 3,
        muscleMass: 101,
        organsFat: 104,
        fatKg: 106,
        bodyWaterPer: 102,
        fatlessMass: 107,
        boneMass: 108,
        proteins: 109,
        ecw: 110,
        tbw: 111,
        icw: 112,
        muscleCoreKg: 113,
        muscleLeftArmKg: 114,
        muscleLeftFootKg: 115,
        muscleRightArmKg: 116,
        muscleRightFootKg: 117,
        clothesKg: 118,
        fatCoreKg: 119,
        fatLeftArmKg: 120,
        fatLeftFootKg: 121,
        fatRightArmKg: 122,
        fatRightFootKg: 123,
        bmi: 124,
        metabolismAge: 125,
        bmr: 126,
        skeletalMass: 127
      },
      basicArrayData: [
        {
          measurement: 'Βάρος',
          key: 'weight',
          desired: '52-66 kg',
          measure: 'kg',
          limits: ''
        },
        {
          measurement: 'Λίπος',
          key: 'fatKg',
          desired: '-',
          measure: 'kg',
          limits: ''
        },
        {
          measurement: 'Μυική Μάζα',
          key: 'muscleMass',
          desired: '-',
          measure: 'kg',
          limits: ''
        },
        {
          measurement: 'Total Body Water',
          key: 'tbw',
          desired: '-',
          measure: 'kg',
          limits: ''
        },
        {
          measurement: 'BMI',
          key: 'bmi',
          desired: '18.5-24.9',
          measure: '',
          limits: ''
        },
        {
          measurement: 'Σπλαχνικό λίπος',
          key: 'organsFat',
          desired: '1-13',
          measure: '',
          limits: '',
        },
        {
          measurement: 'Σκελετική Μυϊκή Μάζα',
          key: 'skeletalMass',
          desired: '-',
          measure: 'kg',
          limits: '',
        },
      ],
      singleRowExtraData: [
        {
          measurement: 'Μεταβολική ηλικία',
          key: 'metabolismAge',
          desired: '-',
          measure: '',
          limits: '',
        },
        {
          measurement: 'ECW',
          key: 'ecw',
          measure: 'kg',
        },
        {
          measurement: 'ICW',
          key: 'icw',
          measure: 'kg',
        },
        {
          measurement: 'Οστική Μάζα',
          key: 'boneMass',
          measure: 'kg',
        },
      ],
      printingBaseResults: {},
      athleteHeight: null,
      isReadyToPrint: false,
    }
  },
  mounted(){
    this.printableMeasurements = [];

    if(!this.colorTemplates.map(x => x.hex).includes(this.color)){
      this.colorTemplates.push({
        name: 'Custom User Color',
        hex: this.color
      })
    }

    this.getAthleteHeight();
  },
  methods:{
    getAthleteHeight(){
      this.$axios.get(`/athlete/${this.athlete.id}/measurements/1`).then(res => {
        this.athleteHeight = res.data.length > 0 ? res.data[res.data.length-1].value: 0;
        this.fixDesiredNumbers();
        for(let measurement of this.athleteMeasurements){
          if(!this.printingBaseResults[measurement.measurementsId]){
            Vue.set(this.printingBaseResults, measurement.measurementsId, isNaN(parseFloat(measurement.value)) ? null :  parseFloat(measurement.value));
          }
        }

      }).catch((e)=>{});
    },

    fixDesiredNumbers(){
      let weightMeasure = this.basicArrayData.find(x => x.key === "weight");
      let tbwMeasure = this.basicArrayData.find(x => x.key === "tbw");
      let fatMeasure = this.basicArrayData.find(x => x.key === "fatKg");
      let muscleMassMeasure = this.basicArrayData.find(x => x.key === "muscleMass");
      let age = this.getAgeNumber;
      weightMeasure.desired = +(18.5 * Math.pow((this.athleteHeight/100), 2)).toFixed(1) + ' - ' + +(24.9 * Math.pow((this.athleteHeight/100), 2)).toFixed(1) + ' kg';


      if(this.athlete.sex === 'M'){
        if(age >= 18 && age <= 39){
          muscleMassMeasure.desired = '75-89%'
          fatMeasure.desired = '7-20%';
        }
        if(age >= 40 && age <= 59){
          muscleMassMeasure.desired = '73-86%'
          fatMeasure.desired = '10.5-22%';

        }
        if(age >= 60 && age <= 79){

          muscleMassMeasure.desired = '70-84%'
          fatMeasure.desired = '13-25%';
        }

        tbwMeasure.desired = '50-65%';
      } else{
        if(age >= 18 && age <= 39){

          muscleMassMeasure.desired = '63-75.5%'
          fatMeasure.desired = '21-33%';
        }
        if(age >= 40 && age <= 59){
          muscleMassMeasure.desired = '62-73.5%'
          fatMeasure.desired = '23-34%';
        }
        if(age >= 60 && age <= 79){
          muscleMassMeasure.desired = '60-72.5%'
          fatMeasure.desired = '24-36.5%';

        }
        tbwMeasure.desired = '45-60%';
      }
      this.isReadyToPrint = true;

    },

    printMeasurements(type){
      if(!this.isReadyToPrint) return;
      this.isPrinting = true;
      this.$nextTick(()=> {
        let divToPrint = this.$refs['print-area'].outerHTML;
        this.isPrinting = false;
        this.print(divToPrint, type, null, 'fat-meter', {
          specificStyles: true,
          styles: `
          .lbsl{position:absolute;z-index:3;bottom:0;height:14px;width:0.1%;background-color:#f9fafb25;}@media print{body {margin: 0px;}}.pagebreak{page-break-before:always};.decoration-bottom-right svg{width: 210mm;height: 142mm;}.decoration-top-left svg{width:100%!important;height:auto;}.print-grid{display:grid;gap:10px;}.print-grid-cols-2{grid-template-columns: repeat(2, minmax(0, 1fr));}.print-grid-cols-3{grid-template-columns: repeat(3, minmax(0, 1fr));}.print-grid-cols-4{grid-template-columns: repeat(4, minmax(0, 1fr));}.print-items-center{align-items:center;}.a4.page{position:relative;width: 210mm;height: 297mm;margin: 0;padding: 0;box-sizing:content-box;}.print-bottom-distancing{margin-bottom:18px;}.print-rounded{border-radius:12px;}.print-rounded-lg{border-radius:22px;}.print-paddings{padding:12px;}.print-text-center{text-align:center;}.print-title{font-size:16px;font-weight:600;}.print-icon svg{max-width:100%;margin:0 auto;}.print-content-area{position:absolute;top:7.5mm;left:7.5mm;right:7.5mm;bottom:7.5mm;}.print-small-title{font-weight:700;font-size:12.5px }.print-regular-text{font-size:11px;}.print-large-text{font-size:12.5px;}.print-measurement-label{font-weight:300;font-size:12px;}.print-measurement-result{font-size:18px;font-weight:600;}
             `
        });
      });
    },
  }

}
</script>
