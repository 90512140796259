<template>
  <div class="print-text-center">
    <div style="margin:0 auto;width:fit-content;border-radius:100%;padding:6px 12px;" :style="`background-color:${colors.primary};color:white`">
      {{isLeft ? 'Α' : 'Δ'}}
    </div>

    <fat-meter-measurement style="margin-top:12px;" label="Χέρι" :value="hand"></fat-meter-measurement>
    <fat-meter-measurement style="margin-top:12px;" label="Πόδι" :value="leg"></fat-meter-measurement>
  </div>
</template>


<script>
import FatMeterMeasurement from "@components/print/fat-meter/FatMeterMeasurement";
export default{
  components: {FatMeterMeasurement},
  props:{
    colors: Object,
    hand: String,
    leg: String,
    isLeft: {
      type: Boolean,
      default: true
    }
  }
}
</script>