<script>
    import moment from 'moment'

export default {
    props: {
        measurements: {
            default:  ()  => { return [] },
            type:Array,
        },
    },
    data() {
        return {
            dates: [],
            options: {
                chart: {
                    id: 'measurement',
                    toolbar: {
                        show: false,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset:false,
                            customIcons: []
                        },
                    },

                },
                stroke: {
                    width: 3,
                    curve: 'smooth'
                },

                markers: {
                    size: 5,
                    opacity: 0.9,
                    colors: ['#56c2d6'],
                    strokeColor: '#fff',
                    strokeWidth: 1,
                    style: 'inverted', // full, hollow, inverted
                    hover: {
                        size: 8,
                    }
                },
                xaxis: {
                    categories: [...this.measurements.map(x => {return this.toDate(x.measurementDate) })]
                },
                yaxis: {
                    forceNiceScale: true
                },
                colors:['#3155A6']
            },

            series: [{
                name: 'Μέτρηση: ',
                data:  [...this.measurements.map(x => {return x.value })]
            }],
        }
    },
    created(){
    },
    methods:{
        toDate(date){
            return moment(date).format('DD/MM/YYYY');
        }
    }
}
</script>
<template>
    <apexchart height="250px" type="line" :options="options" :series="series"></apexchart>
</template>
