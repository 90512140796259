<template>

  <div class="print-rounded print-text-center" :style="`background-color: ${colors.primary};color:white;`"
  style="padding: 5px 0;font-size:13px;">
    {{ title }}
  </div>
</template>

<script>

export default{

  props:{
    title: String,
    colors: Object
  }
}
</script>