<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import athleteMeasurementsChart from "./athleteMeasurementsChart";
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import PaginationRow from "@components/global/PaginationRow";

export default{

    components: {PaginationRow, DatePicker, athleteMeasurementsChart},

    props:{
        athleteId:{
            default: null,
            type: String
        },
        measurements: {
            default: () => { return []},
            type: Array
        },
        populatedMeasurements:{
            default: () => {return []},
            type:Array
        },
        type1: {
            default: '',
            type: String
        },
        type2: {
            default: '',
            type: String
        },
      displayedMeasurements: Array
    },

    mixins: [PaginatedDataMixin],
    data(){
      return {
        componentKey: 0,
        newMeasurement: {
            measurementsId: 1,
            value: '',
            notes: '',
            measurementDate: new Date(),
            athleteId: this.athleteId,
            athleteProgressId: null
        },
        historyMeasurements: [],
        fields: [
          {key: 'value', label:'Τιμή', formatter: (value, key, item) => {
              return this.formatMeasurementValue(item, value);
            }},
          {key: 'notes', label: 'Σημειώσεις', tdClass: 'table-notes'},
          {key: 'measurementDate', label: 'Ημερομηνία', formatter: (value, key, item) => {return moment(value).format('DD/MM/YYYY')}},
          {key: 'actions', label: '', tdClass: 'text-center'}
        ],
        modalMeasurement: {
            id: null,
            value: '',
            notes: '',
            measurementDate: null,
            athleteProgressId: null
        },
      }
    },
    created() {
        this.newMeasurement.measurementsId = this.findMeasurementByType()[0].id;
        this.getMeasurementsById();
        this.api.query.limit = 5;
    },
    methods:{

        formatMeasurementValue(item, value){

            return (this.findMeasurementMeasure(item.measurementsId) !== null) ? value + ' ' + this.findMeasurementMeasure(item.measurementsId): value
        },

        findMeasurementName(id){
            return this.measurements.find( x => x.id === id ).name;
        },

        findMeasurementMeasure(id){
            return this.measurements.find(x=> x.id === id).measure;
        },

        findMeasurementByType(){
            let measurements = [];
            if(!this.type2) measurements =  this.measurements.filter(x=> x.type === this.type1);
            else measurements = this.measurements.filter(x=> x.type === this.type1 || x.type === this.type2 );

            measurements = measurements.filter(x => this.displayedMeasurements.includes(x.id));

            measurements = measurements.sort( (a, b) => {
                return this.populatedMeasurements.some(e => {return  e.measurementsId === b.id }) - this.populatedMeasurements.some(e => {return  e.measurementsId === a.id })
            });

            return measurements;
        },

        getMeasurementsById(){
          this.$axios.get(`/athlete/${this.athleteId}/measurements/${this.newMeasurement.measurementsId}`).then(result => {
              this.historyMeasurements = result.data;
              this.api.count = this.historyMeasurements.length;
              this.componentKey +=1;
          })
        },

        deleteMeasurement(measurement){
          this.$dialog.confirm({
            title:  this.$t('verifyDeletion.title'),
            body: this.$t('verifyDeletion.message')
          }, {
            html: true,
            loader: true,
            okText: this.$t('verifyDeletion.ok'),
            cancelText:  this.$t('verifyDeletion.cancel'),
            backdropClose: true
          }).then((dialog) => {
            this.$axios.delete(`/athlete/${this.athleteId}/measurements/${measurement.id}`).then(() => {
              let index = this.populatedMeasurements.findIndex(x => {return x.measurementsId === measurement.measurementsId});
              if(index>=0){
                this.populatedMeasurements[index].count -=1;
              }
              this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Η μέτρηση διαγράφηκε επιτυχώς!'});
              this.historyMeasurements = this.historyMeasurements.filter(x => {
                return x.id !== measurement.id
              });
              this.onFiltered(this.historyMeasurements);
              this.componentKey +=1;
              this.$emit('measurementsChanged');
            }).catch(e=>{
              this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'),
                text: this.$t('errors.text')});
            }).finally(()=>{
              dialog.close();
            });
          }).catch(()=>{});

        },
        addMeasurement(){
            if(this.newMeasurement.value === '') return;
            this.$axios.post(`/athlete/${this.athleteId}/measurements`, this.newMeasurement).then(result =>{
                let index = this.populatedMeasurements.findIndex(x => {return x.measurementsId === this.newMeasurement.measurementsId});
                if(index<0){
                    this.populatedMeasurements.push({measurementsId: this.newMeasurement.measurementsId, count: 1});
                }else{
                    this.populatedMeasurements[index].count +=1;
                }
                // this.populatedMeasurements[this.newMeasurement.measurementsId] += 1;
                this.getMeasurementsById();
                this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Η μέτρηση προστέθηκε επιτυχώς!'});
                this.newMeasurement.value ='';
                this.newMeasurement.notes = '';
                this.$emit('measurementsChanged');
            }).catch(error => {
                if(error){
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η προσθήκη της μέτρησης απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                }
            });
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.api.count = filteredItems.length;
            this.api.page = 1
        },
        formatDate(date){
            return moment(date).format('DD/MM/YYYY');
        },
        openEditMeasurementModal(item){
            this.modalMeasurement ={
                id: item.id,
                value: item.value,
                notes: item.notes,
                measurementDate: item.measurementDate,
                athleteProgressId: item.athleteProgressId
            };
            this.$refs['edit-measurement-modal'].show();
        },
        updateMeasurement(){

            this.$axios.put(`/athlete/${this.athleteId}/measurements/${this.modalMeasurement.id}`, {newValues: this.modalMeasurement}).then(result=>{
                this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Η μέτρηση ενημερώθηκε επιτυχώς!'});
                this.$refs['edit-measurement-modal'].hide();
                let item = this.historyMeasurements.find(x => x.id === this.modalMeasurement.id);
                item.value = result.data.value;
                item.notes = result.data.notes;
                item.measurementDate = result.data.measurementDate;
                this.componentKey +=1;
                this.$emit('measurementsChanged');
            }).catch(e=>{
                this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση της μέτρησης απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
            });

        },
        updateCurrentMeasurement(id){
            this.newMeasurement.measurementsId = id;
            this.getMeasurementsById();
        },
        getHistoryMeasurements(){
            let currPage = this.api.page - 1;
            let measurements = this.historyMeasurements.slice().reverse();
            let start = currPage*this.api.query.limit;
            return measurements.slice(start, (start+1)*this.api.query.limit);
        },

        checkMeasurement(value, measurementId){
            let lowValue =  this.measurements.find(x=>x.id === measurementId).lowValue;
            let highValue =  this.measurements.find(x=>x.id === measurementId).highValue;

            if(lowValue === 0 && highValue === 0) return true;
            return value > lowValue && value < highValue;
        },

        getLowMeasurementValue(measurementId){
            return this.measurements.find(x=>x.id === measurementId).lowValue;
        },
        getHighMeasurementValue(measurementId){
            return this.measurements.find(x=>x.id === measurementId).highValue;
        },

        getMeasurementClass(value, measurementId){
            let lowValue =  this.measurements.find(x=>x.id === measurementId).lowValue;
            let highValue =  this.measurements.find(x=>x.id === measurementId).highValue;

            if(lowValue === 0 && highValue === 0) return '';

            if(value >= lowValue && value <= highValue) return 'ok-measurement';
            return 'bad-measurement';
        },
        getCount(measurementId){
            let exists = this.populatedMeasurements.find(x => { return x.measurementsId === measurementId});
            return exists? exists.count : '0';
        },

        getMeasurementButtonClass(measurementId){

            if(this.newMeasurement.measurementsId === measurementId) return 'active-measurement';
            let exists = this.populatedMeasurements.find(x => { return x.measurementsId === measurementId});
            return exists? 'has-measurement' : 'no-measurement';
        }

    }
}

</script>
<template>
<div class="content">
  <div class="card">
    <div class="card-body row">
        <div class="col-md-12">
            <h5 class="mb-3 bg-light p-2"><i class="fe-bar-chart-line" ></i> ΕΠΙΛΟΓΗ ΜΕΤΡΗΣΗΣ </h5>
            <b-button v-for="measurement in findMeasurementByType()" :key="measurement.id"
                      class="m-1"
                      variant="light"
                      size="sm" :class="getMeasurementButtonClass(measurement.id)"
                      @click="updateCurrentMeasurement(measurement.id)">
                {{measurement.name}} <span class="text-muted">({{getCount(measurement.id)}})</span>
            </b-button>
        </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body row">
        <div class="col-md-6">
          <div v-if="newMeasurement.measurementsId">
            <h5 class="mb-3 bg-light p-2"><i class="fe-rotate-ccw"></i> Ιστορικό Μετρήσεων - {{findMeasurementName(newMeasurement.measurementsId)}} </h5>
            <b-alert v-if="historyMeasurements.length < 1" show variant="warning">Δεν υπάρχουν μετρήσεις!</b-alert>
            <div v-if="historyMeasurements.length > 0" class="mb-0">
              <b-card v-for="measurement in getHistoryMeasurements()" :key="measurement.id" class="shadow-sm">
                <b-card-text>
                    <div class="row">
                        <div class="col-md-3">
                            <span class="small-size"><strong>Τιμή</strong></span><br>

                            <span :id="'measurement-' + measurement.id" class="value" :class="getMeasurementClass(measurement.value, measurement.measurementsId)">
                                <span v-if="getMeasurementClass(measurement.value, measurement.measurementsId) !== ''">
                                    <span v-if="!checkMeasurement(measurement.value, measurement.measurementsId)"><i class="fas fa-exclamation-triangle"></i></span>
                                    <span v-else><i class="fas fas fa-check-circle"></i></span>
                                </span>

                                {{formatMeasurementValue(measurement, measurement.value)}}
                            </span>
                            <b-tooltip v-if="getLowMeasurementValue(measurement.measurementsId) > 0 && getHighMeasurementValue(measurement.measurementsId) > 0 " :target="'measurement-' + measurement.id" triggers="hover">
                                Φυσιολογικό εύρος τιμών:<br> {{getLowMeasurementValue(measurement.measurementsId)}} έως {{getHighMeasurementValue(measurement.measurementsId)}}
                            </b-tooltip>
                            <b-tooltip v-else-if="getHighMeasurementValue(measurement.measurementsId) > 0 " :target="'measurement-' + measurement.id" triggers="hover">
                                Φυσιολογικό εύρος τιμών:<br> Έως {{getHighMeasurementValue(measurement.measurementsId)}}
                            </b-tooltip>
                        </div>
                        <div class="col-md-3">
                            <span class="small-size"><strong>Σημειώσεις</strong></span><br> {{measurement.notes}}
                        </div>
                        <div class="col-md-3">
                            <span class="small-size"><strong>Ημερομηνία</strong></span><br> {{formatDateWithoutHour(measurement.measurementDate)}}
                        </div>
                        <div class="col-md-3">
                            <span class="small-size"><strong>Ενέργειες</strong></span><br>
                            <b-button v-if="measurement.athleteProgressId === null" class="mr-1" size="xs" variant="warning" @click="openEditMeasurementModal(measurement)"> <i class="fe-edit-1"></i></b-button>
                            <b-button v-if="measurement.athleteProgressId === null" size="xs" variant="danger" @click="deleteMeasurement(measurement)"><i class="fe-trash"></i></b-button>

                        </div>
                    </div>


                </b-card-text>
              </b-card>
              <pagination-row :api="api"></pagination-row>
            </div>

            </div>
        </div>
        <div class="col-md-6">
            <h5 class="mb-3 bg-light p-2"><i class="fe-bar-chart-line" ></i> Διάγραμμα μεταβολής - {{findMeasurementName(newMeasurement.measurementsId)}}</h5>
            <athlete-measurements-chart :key="componentKey" :measurements="historyMeasurements"></athlete-measurements-chart>
        </div>

        <div class="col-md-6 pt-5">
            <h5 class="mb-3 bg-light p-2"><i class="fe-plus-circle"></i> Προσθήκη μέτρησης - {{findMeasurementName(newMeasurement.measurementsId)}}</h5>
            <b-form @submit.prevent="addMeasurement">
                <div class="row">
                    <div class="col-md-6">
                        <b-form-group id="value" label="Τιμή Μέτρησης:" label-for="value">
                            <b-input-group :append="findMeasurementMeasure(newMeasurement.measurementsId)" >
                                <b-form-input id="value" v-model="newMeasurement.value" type="text" @input="newMeasurement.value = replaceCommaWithDot(newMeasurement.value)"></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group id="date" label="Ημερομηνία μέτρησης" label-for="date">
                            <date-picker v-model="newMeasurement.measurementDate" :first-day-of-week="1" :lang="elDatePicker" format="DD-MM-YYYY"></date-picker>
                        </b-form-group>
                    </div>
                </div>

                <b-form-group id="input-notes" label="Σημειώσεις (προαιρετικό)" label-for="notes">
                    <b-form-textarea id="notes" v-model="newMeasurement.notes" type="textarea" placeholder="Σημειώσεις σχετικά με τη μέτρηση" rows="6"></b-form-textarea>
                </b-form-group>

                <b-form-group id="add-measurement" class="mt-2 text-right">
                    <b-button type="submit" variant="primary" class="btn btn-primary mt-2" :disabled="newMeasurement.value === ''"><i class="fe-plus"></i> Προσθήκη μέτρησης</b-button>
                </b-form-group>
            </b-form>
        </div>


    </div>
  </div>


  <b-modal ref="edit-measurement-modal" title="Επεξεργασία μέτρησης" hide-footer body-class="p-4">
      <b-alert :show="modalMeasurement.athleteProgressId !== null" variant="warning">Αυτή η μέτρηση έχει περαστεί από το φύλλο παρακολούθησης και δεν μπορεί να επεξεργαστεί εδώ</b-alert>
      <b-form @submit.prevent="updateMeasurement">
          <div class="row">
              <div class="col-md-6">
                  <b-form-group id="edit-value" label="Τιμή Μέτρησης:" label-for="edit-value">
                      <b-input-group :append="findMeasurementMeasure(newMeasurement.measurementsId)" >
                          <b-form-input id="edit-value" v-model="modalMeasurement.value" type="text" :disabled="modalMeasurement.athleteProgressId !== null" @input="modalMeasurement.value = replaceCommaWithDot(modalMeasurement.value)"></b-form-input>
                      </b-input-group>
                  </b-form-group>
              </div>
              <div class="col-md-6">
                  <b-form-group id="edit-date" label="Ημερομηνία μέτρησης" label-for="date">
                      <date-picker v-model="modalMeasurement.measurementDate" :first-day-of-week="1" :lang="elDatePicker" format="DD-MM-YYYY" :disabled="modalMeasurement.athleteProgressId !== null"></date-picker>
                  </b-form-group>
              </div>
          </div>

          <b-form-group id="edit-input-notes" label="Σημειώσεις (προαιρετικό)" label-for="edit-input-notes">
              <b-form-textarea id="edit-input-notes" v-model="modalMeasurement.notes" type="textarea" placeholder="Σημειώσεις σχετικά με τη μέτρηση" rows="6"></b-form-textarea>
          </b-form-group>

          <b-form-group v-if="modalMeasurement.athleteProgressId === null" id="button-group" class="mt-2 text-right">
            <save-button type="submit"></save-button>
          </b-form-group>
      </b-form>
  </b-modal>

</div>
</template>
<style lang="scss">
.no-measurement{
    opacity:0.8;
}
.ok-measurement{
    padding:0.2rem;
    background-color:#d4edda;
    color:#155724;
}
.bad-measurement{
    padding:0.2rem;
    background-color:#f8d7da;
    color:#721c24;
}
</style>
