<template>


  <div class="grid grid-cols-12 align-items-center gap-2 py-2">
    <div class="col-span-1">
      <b-form-checkbox :id="goal.id" :name="goal.id" :checked="goal.is_completed" @change="$emit('toggleGoal', goal)"></b-form-checkbox>
    </div>
    <div class="col-span-9">
      {{goal.goal}}
    </div>
    <div class="col-span-2 text-right">
      <b-button variant="danger" size="xs" @click="$emit('deleteGoal', goal.id)" :disabled="isSubmitting"><span class="icon-trash"></span></b-button>
    </div>
  </div>
</template>

<script>

export default{

  props:{
    goal: Object,
    isSubmitting: Boolean
  },

  data(){
    return{

    }
  }

}
</script>