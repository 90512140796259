<template>

  <div id="athlete-events">
    <div class="mt-0 pt-0">
      <b-tabs justified nav-class="nav-tabs nav-bordered">
        <b-tab title="Μελλοντικά">

          <Event v-for="event in futureEvents" :key="event.id" :event="event"/>
        </b-tab>
        <b-tab title="Ολοκληρωμένα">
          <Event v-for="event in pastEvents" :key="event.id" :event="event" :is-past="true"/>
        </b-tab>
      </b-tabs>
    </div>

  </div>
</template>

<style lang="scss">
#athlete-events{
  .card-body{
    padding:.2em;
  }
}

</style>
<script>

import moment from "moment";
import Event from "@components/Event";

export default{
  components: {Event},
  props:{
    athleteId: String
  },
  data(){
    return{
      futureEvents: [],
      pastEvents: []
    }
  },
  mounted(){
    this.fetchEvents();
  },
  methods:{
    fetchEvents(){
      this.$axios.get(`/athlete/${this.athleteId}/events`).then(res=>{

        for(let event of res.data){
          event.start =  moment.utc(event.date).set('hour', event.startTime.substring(0, 2)).set('minute', event.startTime.substring(3, 5)).toISOString();
          event.end = moment.utc(event.date).set('hour', event.endTime.substring(0, 2)).set('minute', event.endTime.substring(3, 5)).toISOString();
          if(new Date(event.start) < new Date()){
            this.pastEvents.push(event);
          }else if(new Date(event.start) > new Date()){
            this.futureEvents.push(event);
          }
        }
        this.futureEvents.sort((a,b)=>{
          return new Date(a.date) - new Date(b.date)
        });
        this.pastEvents.sort((a,b)=>{
          return new Date(b.date) - new Date(a.date)
        })
      }).catch(e=>{
          console.log(e);
        })
    }
  }

}
</script>
