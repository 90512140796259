<template>

  <div class="print-measurements-wrapper">
    <form-wizard
        v-model="printOptions.step" back-button-text="Πίσω" next-button-text="Επόμενο" finish-button-text="Εκτύπωση"
                 color="#3155A6" error-color="#dc3545"
                 @on-complete="printMeasurements">

      <tab-content title="Βασικά στοιχεία" icon="icon-user" :before-change="verifyMeasurementsSelected" :lazy="true">
        <div class="row print-content-wrap">
          <div v-for="(category, index) of measurementCategories" :key="'category-' + category" :title="measurementCategoryTitles[index]" class="d-block w-100">

            <h5>{{measurementCategoryTitles[index]}}</h5>
            <b-form-checkbox-group v-model="printOptions.selectedMeasurements" name="some-radios" class="multiselect-measurements">
                <b-form-checkbox v-if="displayedMeasurements.includes(item.id)"
                                 v-for="item in measurements.filter(x => x.type === category)" :key="item.id" :value="item.id" style="width:30%;">
                  {{item.name}}</b-form-checkbox>
            </b-form-checkbox-group>
            <hr>
          </div>
        </div>
      </tab-content>
      <tab-content title="Επιλογές εκτύπωσης" icon="icon-settings" :lazy="true">
        <div class="row mb-4">
          <div class="col-12">
            <h5>Επιλογές εκτύπωσης</h5>
            <b-form-checkbox id="showDiagrams" v-model="printOptions.showDiagrams" name="showDiagrams">Προβολή διαγραμμάτων</b-form-checkbox>
            <b-form-checkbox id="showText" v-model="printOptions.showText" name="showText">Προβολή κειμένου</b-form-checkbox>
          </div>
        </div>
        <div id="print-area" ref="print-area">

          <print-header v-show="isPrinting" :title="'<strong>Πρόοδος πελάτη</strong>'"/>

          <div v-if="measurement.values.length > 0" v-for="measurement of printableMeasurements" :key="measurement.name"
               :style="isPrinting ? 'display:block;' : 'display:none;'">
            <div class="measurement-line row align-items-center " style="padding-bottom:1em;padding-top:1em;break-inside: avoid;">
              <div class="col-12 name" style="background-color:#fafbfe;padding:.7em;border-radius:8px;">
                <strong>{{measurement.name}}</strong>
              </div>

              <div v-if="printOptions.showText" class="col-12">
                <div class="row">
                  <div v-for="(date,index) in measurement.dates" :key="'date-'+date" class="col-md-2 value" style="text-align:center;margin-top:1em;">
                    <div class="text-muted date-muted"> {{date}}</div>
                    <div class="value-number">
                      {{measurement.values[index]}} {{measurement.measure}}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="printOptions.showDiagrams" class="col-12" style="margin-top:1em;">
                <apexchart width="800" height="200" type="line" :options="getChartOptions(measurement)" :series="getChartValues(measurement)"></apexchart>
              </div>

            </div>
          </div>

        </div>
      </tab-content>
    </form-wizard>
  </div>

</template>

<style lang="scss">
.print-measurements-wrapper{
  .print-content-wrap{
    max-height:500px;
    overflow-y:scroll;
  }
}
</style>


<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import printHeader from '@components/print/header';
import PrintMixin from "@src/mixins/PrintMixin";

export default{

  props:{
    measurements: Array,
    displayedMeasurements:Array,
    measurementCategories: Array,
    measurementCategoryTitles: Array,
    bulkMeasurements: Object
  },

  components: {FormWizard, TabContent, printHeader},
  mixins: [PrintMixin],
  data(){
    return{
      printOptions:{
        selectedMeasurements: [],
        showDiagrams: true,
        showText: true,
        maxMeasurements: 10,
        step:1
      },
      isPrinting: false,
      printableMeasurements: []
    }
  },
  mounted(){
    this.printableMeasurements = [];
  },
  methods:{
    verifyMeasurementsSelected(){
      if(this.printOptions.selectedMeasurements.length === 0) return false;
      this.prepareMeasurements();
      return true;
    },
    printMeasurements(){
      this.isPrinting = true;
      this.$nextTick(()=>{
        let divToPrint = this.$refs['print-area'].outerHTML;
        this.isPrinting = false;
        this.print(divToPrint);
      });
    },
    findMeasurement(category, date, id){
      let measurement = this.bulkMeasurements[category].measurements.find(x => x.measurementDate === date && x.measurementsId === id);
      return measurement ? measurement.value : '-'
    },
    prepareMeasurements(){
      this.printableMeasurements = [];
      for(let measure of this.printOptions.selectedMeasurements){

        let measurement = this.measurements.find(x => x.id === measure);
        let finalObj = {
          id: measurement.id,
          name: measurement.name,
          dates: [],
          values: [],
          measure: measurement.measure
        }
        let bulkMeasurements ={...this.bulkMeasurements[measurement.type]};
        for(let date of bulkMeasurements.dates){
          let item = bulkMeasurements.measurements.find(x => x.measurementsId === measure & x.measurementDate === date);

          if(!item) continue;

          finalObj.dates.push(date);
          finalObj.values.push(item.value);

        }

        this.printableMeasurements.push(finalObj);

      }
    },
    getChartOptions(toPrintMeasure){
      return {
        chart: {
          id: 'chart' + toPrintMeasure.id,
          toolbar: {
            show: false,
          },

        },
        markers: {size:3,strokeColors: '#3155A6',},
        yaxis: {
          forceNiceScale: true,
          labels:{
            formatter: (value) => { return +value.toFixed(1) + ' ' + toPrintMeasure.measure },
          }
        },
        stroke:{
          width: 2,
          curve: 'smooth'
        },
        animations:{
          enabled: false
        },
        tooltip:{
          enabled:false
        },
        grid:{
          borderColor: '#e1e1e1',
          xaxis:{
            lines:{show:false}
          }
        },

        xaxis: {
          categories: toPrintMeasure.dates,
        },
        colors:['#3155A6']
      }
    },
    getChartValues(toPrintMeasure){
      return [{
        name: toPrintMeasure.name,
        data: toPrintMeasure.values
      }]
    }
  }

}
</script>
