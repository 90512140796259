<template>

  <div id="mass-import">


    <b-alert variant="warning" :show="true"><p class="font-weight-bold mb-0 pb-0" style="font-size:0.9rem;">Η εισαγωγή από λιπομετρητή είναι σε δοκιμαστική λειτουργία.</p>
      Εάν αντιμετωπίσετε κάποιο πρόβλημα, δυσκολία ή τα νούμερα που βλέπετε δεν είναι σωστά, επικοινωνήστε μαζί μας στο
      info@athlisis.com και μην ξεχάσετε να συμπεριλάβετε το αρχείο CSV που προσπαθήσατε να εισάγετε, το μοντέλο του λιπομετρητή σας, και ένα PDF της τωρινής σας εκτύπωσης.
    </b-alert>

    <div v-if="step === 0" class="step-0">

      <p>Με αυτή τη λειτουργία μπορείτε να εισάγετε λιπομέτρηση από τον λιπομετρητή σας (CSV αρχείο).</p>


      <vue-dropzone  v-show="showZone" id="dropzone" ref="upload-csv" :options="dropzoneOptions"
                    @vdropzone-success="completed" class="mt-3 mb-3"></vue-dropzone>
    </div>

    <div v-if="step === 1" class="step-1">

      <div class="row">
        <div class="col-md-12">
          <b-form-group id="date" label="Ημερομηνία μέτρησης" label-for="date">
            <date-picker v-model="measurementModal.date" :first-day-of-week="1" :lang="elDatePicker" format="DD-MM-YYYY"></date-picker>
          </b-form-group>
        </div>

        <div v-for="measurement in getVisibleMeasurements" :key="measurement.id" class="mb-2 col-md-4">
          <label>{{measurement.name}}:</label>
          <b-input-group :append="measurement.measure" >
            <b-form-input  v-model="measurementModal.measurements[measurement.id]" type="text" @input="measurementModal.measurements[measurement.id] = replaceCommaWithDot(measurementModal.measurements[measurement.id])"></b-form-input>
          </b-input-group>
        </div>

        <div class="col-12 mt-4">

        </div>
      </div>

      <div class="text-right">

        <b-button variant="primary" class="mr-3" size="xs" @click="closeModal">Κλείσιμο χωρίς αποθήκευση</b-button>
        <b-button variant="success" @click="addMeasurements">Εισαγωγή μετρήσεων</b-button>
      </div>

    </div>

  </div>

</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import DatePicker from "vue2-datepicker";
export default {
  components: {vueDropzone: vue2Dropzone, DatePicker},

  props:{
    measurements:Array
  },

  data(){
    return{
      step:0,
      showZone: true,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API}/general/measurements-csv-upload`,
        maxFilesize: 1,
        headers: { "x-access-token": JSON.parse(window.localStorage.getItem('auth.token'))},
        acceptedFiles: '.csv',
        maxFiles: 1,
        dictDefaultMessage: 'Σύρτε εδώ ένα αρχείο csv ή πατήστε πάνω στο πλαίσιο'
      },
      results: null,
      measurementsList: [],
      measurementModal:{
        date: new Date(),
        notes: null,
        measurements: {}
      },
    }
  },
  computed:{

    getVisibleMeasurements(){
      return this.measurements.filter(x => Object.keys(this.results).map(x => parseInt(x)).includes(x.id));
    }

  },
  mounted(){
    this.getInitialData();
  },
  methods:{

    addMeasurements(){

      this.$axios.post(`/athlete/${this.$route.params.athleteId}/measurements/bulk-insert`, this.measurementModal).then( res => {
        this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Οι μετρήσεις προστέθηκαν επιτυχώς!'});
        this.measurementModal.measurements = {};
        this.$emit('refetchMeasurements');
        this.$emit('closeModal')
      }).catch(e => {
        this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η προσθήκη των μετρήσεων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
      })
    },

    getInitialData(){
      this.$axios.get('/general/measurements-csv-import').then(res => {
        this.measurementsList = res.data.fields;
      }).catch(e=>{
        console.error(e);
      })
    },
    completed(file, res){
      if(res.error){
        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'),
          text: 'Το CSV που έχετε εισάγει είναι εσφαλμένο. Επιλέξατε τον σωστό λιπομετρητή;'});
        this.$refs['upload-csv'].removeAllFiles();
        return;
      }
      this.results = res;


      for(let [key, value] of Object.entries(res)){
        this.measurementModal.measurements[key] = isNaN(parseFloat(value.value)) ? null :  parseFloat(value.value)
      }

      this.step++;

    },
    closeModal(){
      this.$emit('closeModal')
    }
  }

}
</script>
