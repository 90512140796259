<template>

  <div id="athlete-mobile-goals" class=" card" style="background-color:white;">
    <b-tabs content-class="mt-1" nav-class="nav-tabs nav-bordered" style="height:400px;overflow-y:auto;" fill >
      <b-tab title="Στόχοι">

        <athlete-mobile-goal-item v-for="item in activeGoals" :key="item.id" :goal="item" :is-submitting="isSubmitting"
                                  @toggleGoal="toggleGoal"
                                  @deleteGoal="deleteGoal"
        ></athlete-mobile-goal-item>

      </b-tab>
      <b-tab title="Εκπληρωμένοι στόχοι">
        <athlete-mobile-goal-item v-for="item in completedGoals"  :key="item.id" :goal="item" :is-submitting="isSubmitting"
                                  @toggleGoal="toggleGoal"
                                  @deleteGoal="deleteGoal"></athlete-mobile-goal-item>

      </b-tab>
    </b-tabs>


    <div class="d-flex align-items-center mb-2">
      <b-form-input v-model="newGoal.goal" class="mr-2" @keydown.enter="createGoal" placeholder="Πληκτρολογήστε τον στόχο..."></b-form-input>
      <b-button variant="primary" class="h-100 my-0"   @click="createGoal" :disabled="isSubmitting">+</b-button>
    </div>

    <b-alert class="text-center" variant="warning" show>Οι στόχοι αυτοί μοιράζονται αυτόματα στο mobile app του πελάτη σας.</b-alert>

  </div>
</template>

<script>
import AthleteMobileGoalItem from "@components/athletes/partials/AthleteMobileGoalItem";
export default{
  components: {AthleteMobileGoalItem},
  props:{

  },

  created(){
    this.getGoals();
  },
  data(){
    return{

      goals: [],
      newGoal: {
        goal: null
      },
      isSubmitting: false
    }
  },

  computed:{

    completedGoals(){

      return this.goals.filter(x => x.is_completed);
    },

    activeGoals(){
      return this.goals.filter(x => !x.is_completed);
    }
  },

  methods:{

    getGoals(){

      this.$axios.get(`/athlete/${this.$route.params.athleteId}/mobile-goals`).then(res=>{
        this.goals = res.data;
      }).catch(e=>{
        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});
      })
    },

    createGoal(){
      this.isSubmitting = true;
      this.$axios.post(`/athlete/${this.$route.params.athleteId}/mobile-goals`, this.newGoal).then(res=>{
        this.goals.unshift(res.data);
        this.newGoal.goal = null;
      }).catch(e=>{
        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});
      }).finally(()=>{
        this.isSubmitting = false;
      })
    },

    toggleGoal(goal){
      this.isSubmitting = true;
      goal.is_completed = !goal.is_completed
      this.$axios.put(`/athlete/${this.$route.params.athleteId}/mobile-goals/${goal.id}`, goal).then(()=>{

        this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: this.$t('success.text')});
      }).catch(e=>{

        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});

      }).finally(()=>{
        this.isSubmitting = false;
      })

    },
    deleteGoal(goalId){
      this.isSubmitting = true;
      this.$axios.delete(`/athlete/${this.$route.params.athleteId}/mobile-goals/${goalId}`).then(res=>{
        this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: this.$t('success.text')});
        this.goals.splice(this.goals.findIndex( x=> x.id === goalId), 1);
      }).catch(e=>{
        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});
      }).finally(()=>{
        this.isSubmitting = false;
      })
    }
  }
}
</script>