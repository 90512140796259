<script>
    import Vue from "vue"
    import athleteMeasurementsContent from "./athleteMeasurementsContent";
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import { quillEditor } from 'vue-quill-editor'
    import DatePicker from "vue2-datepicker";
    import moment from "moment"
    import MeasurementSettings from "@views/athletes/athlete/measurements/measurementSettings";
    import PrintAthleteMeasurementsModal from "@views/athletes/athlete/measurements/PrintAthleteMeasurementsModal";
    import MeasurementsCsvImport from "@components/modals/MeasurementsCsvImport";
    import FatMeterPrintModal from "@views/athletes/athlete/measurements/FatMeterPrintModal";
    import AthleteMeasurementsList from "@components/athletes/athlete-measurements-list";

    export default {
        components: {
          AthleteMeasurementsList,
          FatMeterPrintModal,
          MeasurementsCsvImport,
          MeasurementSettings, DatePicker, athleteMeasurementsContent, quillEditor, PrintAthleteMeasurementsModal},

        props:{
            athlete: {
                default: () => {
                    return {}
                },
                type: Object
            },
        },

        data(){
            return {
                measurements: [],
                populatedMeasurements: [],
                displayedMeasurements: [],
                measurementsTabId: 0,
                patientNotes: "",
                isPatientNotesLoaded: true,
                notesChanged: false,
                measurementModal:{
                  date: new Date(),
                  notes: null,
                  measurements: {
                  }
                },
                showMassiveView: false,
                massiveViewLoaded: false,
                athleteMeasurements: null,
                bulkMeasurements: {},
                measurementsChanged: false,
                isPrinting: false,
                measurementCategories: ['BASIC', 'DERMATOPTUXES', 'MEZOURES', 'VITAMINS', 'GENERAL', 'BIOCHEMICAL', 'HORMONES', 'EXTRA_FOR_FATMETER'],
                measurementCategoryTitles: ['Σωματομετρικές', 'Δερματοπτυχές', 'Μεζούρες', 'Βιταμίνες', 'Γενικές Αιματολογικές', 'Βιοχημικές', 'Ορμονολογικές', 'Extra (λιπομετρητές)'],
            }
        },
        computed:{
            editor() {
                return this.$refs.noteEditor.quill
            },
          athleteId(){
              return this.athlete.id;
          }
        },

        created() {
            this.syncMeasurements();
        },

        beforeDestroy() {
            if(this.notesChanged){
                this.updateGlobalNotes();
            }
        },

        methods: {

            syncMeasurements(){
                this.$axios.get(`/athlete/${this.athleteId}/measurements/list`).then( result => {
                  if(this.athlete.sex === 'F'){
                    this.measurements = result.data.measurements.filter(x => {return !!x.isWoman});
                  }else{
                    this.measurements = result.data.measurements.filter(x => {return !!x.isMan});
                  }
                  this.populatedMeasurements = result.data.usedMeasurements;
                  this.displayedMeasurements = result.data.displayedMeasurements ?
                      JSON.parse(result.data.displayedMeasurements) : result.data.measurements.map(x => x.id);
                });
            },

            updateGlobalNotes(){
                this.$axios.put(`/athlete/${this.athleteId}/measurements/notes`, {measurementNotes: this.athlete.measurementNotes}).then( result => {
                    this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Οι ενημερώσεις ανανεώθηκαν επιτυχώς!'});
                    this.notesChanged = false;
                });
            },
            bulkGetMeasurements(reSync = false){
              if(!this.measurementsChanged && !reSync && Object.keys(this.bulkMeasurements).length > 0) return;
              this.$axios.get(`/athlete/${this.athleteId}/measurements`).then( result => {
                this.athleteMeasurements = result.data;
                this.athleteMeasurements = this.athleteMeasurements.map(measurement => {
                  measurement.measurementDate = this.formatDateWithoutHour(measurement.measurementDate);
                  let measure = this.measurements.find(x => x.id === measurement.measurementsId);
                  measurement.measurementType = measure ? measure.type : null;
                  return measurement;
                });
                this.formatMeasurementsForModalView();
                this.measurementsChanged = false; // we flag off the "changed" measurements status.
              });
            },

            formatMeasurementsForModalView(){

              // group and match dates.
              for (let category of this.measurementCategories){
                this.bulkMeasurements[category] = {
                  measurements: this.athleteMeasurements.filter(x => x.measurementType === category),
                  dates: new Set()
                }
              }

              // gather unique dates and add them inside the SET
              for (let value of Object.keys(this.bulkMeasurements)) {
                for(let measurement of this.bulkMeasurements[value].measurements){
                  this.bulkMeasurements[value].dates.add(measurement.measurementDate);
                }

                this.bulkMeasurements[value].dates = Array.from(this.bulkMeasurements[value].dates);
                this.bulkMeasurements[value].dates = this.bulkMeasurements[value].dates.slice().sort((a,b) =>
                    (moment(a, "DD/MM/YYYY").toDate() - moment(b, "DD/MM/YYYY").toDate())
                )
              }
              this.massiveViewLoaded = true;
            },

            addMeasurements(){

                this.$axios.post(`/athlete/${this.athleteId}/measurements/bulk-insert`, this.measurementModal).then( res => {
                  this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Οι μετρήσεις προστέθηκαν επιτυχώς!'});
                  this.measurementModal.measurements = {};
                  this.syncMeasurements();
                  this.$refs['massive-insertion'].hide();
                }).catch(e => {
                  this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η προσθήκη των μετρήσεων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                })
            },

            findMeasurement(category, date, id){
              let measurement = this.bulkMeasurements[category].measurements.find(x => x.measurementDate === date && x.measurementsId === id);
              return measurement ? measurement.value : '-'
            },
            openMassiveViewModal(){
                this.bulkGetMeasurements();
                this.showMassiveView = true;
                this.$refs['massive-view'].show();
            },

          openPrintModal(){
            this.bulkGetMeasurements();
            this.showMassiveView = true;
            this.$refs['print-window'].show();
          },

          openPrintFatMeterModal(){
              this.bulkGetMeasurements();
              this.showMassiveView = true;
              this.$refs['fat-meter-print-window'].show();
          },
          openSettingsModal(){
              this.$refs['measurement-settings'].show();
          },
          displayedMeasurementsChanged(){
              this.syncMeasurements();
              this.$refs['measurement-settings'].hide();
          },
          resetBulkMeasurements(){
            this.bulkMeasurements = {};
            this.massiveViewLoaded = false;
            this.measurementsChanged = true;
          }
        }
    }

</script>
<style lang="scss">
    .active-measurement{
        background-color:$color_primary!important;
        color:#fff;
        &:focus{
            color:#fff;
        }
        &:hover{
            color:#fff;
        }
        &:active{
            color:#fff!important;
        }
    }
    .ql-editor{
        min-height: 200px;
    }
    .measurement-line{
      .date-muted{
        font-size:0.8em;
        margin-bottom:0.2em;margin-top:1em;
      }
      .value{
        @media(max-width:767px){
          width:30%;
        }
        .value-number{
          font-weight:Bold;
        }
      }
    }
    .measurement-tab-content{
      max-height:500px;
      overflow-y:auto;
      overflow-x:hidden;
    }
</style>
<template>
    <div v-if="measurements.length > 0" >
        <div class="row">
          <div class="col-md-2">
            <b-button variant="light" class="mr-2" @click="openSettingsModal"><i class="icon-settings"></i> Ρυθμίσεις μετρήσεων</b-button>
          </div>
          <div class="col-md-10 text-right">
            <b-button variant="outline-primary" @click="openPrintFatMeterModal" class="mr-2">
              Εκτύπωση Λιπομετρητή <b-badge variant="primary" size="xs">beta</b-badge></b-button>

            <b-button variant="light" @click="openPrintModal" class="mr-2"><i class="fe-printer"></i> Εκτύπωση</b-button>
            <b-button variant="link" class="mr-2" @click="openMassiveViewModal">Προβολή προόδου</b-button>
            <b-button variant="outline-primary" class="mr-2" @click="$refs['import-from-csv'].show()">{{$t('components.athlete.measures.importCsv')}}</b-button>
            <b-button variant="primary" @click="$refs['massive-insertion'].show()">Μαζική εισαγωγή μετρήσεων</b-button>
          </div>
        </div>
        <b-modal id="massive-insertion" ref="massive-insertion" title="Μαζική εισαγωγή μετρήσεων" size="xl" hide-footer>
          <div class="row">
            <div class="col-md-12">
              <b-form-group id="date" label="Ημερομηνία μέτρησης" label-for="date">
                <date-picker v-model="measurementModal.date" :first-day-of-week="1" :lang="elDatePicker" format="DD-MM-YYYY"></date-picker>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-tabs>
                <b-tab v-for="(category, index) of measurementCategories" :key="'category-' + category" :title="measurementCategoryTitles[index]">
                  <div class="row">
                    <div v-for="measurement in measurements.filter(x => x.type === category && displayedMeasurements.includes(x.id))" :key="measurement.id" class="mb-2 col-md-6">
                      <label>{{measurement.name}}:</label>
                      <b-input-group :append="measurement.measure" >
                        <b-form-input  v-model="measurementModal.measurements[measurement.id]" type="text" @input="measurementModal.measurements[measurement.id] = replaceCommaWithDot(measurementModal.measurements[measurement.id])"></b-form-input>
                      </b-input-group>
                    </div>
                  </div>
                </b-tab>

              </b-tabs>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12 text-right">
              <b-button variant="primary" @click="addMeasurements"> Προσθήκη μετρήσεων</b-button>
            </div>

          </div>
        </b-modal>

        <b-modal id="massive-view" ref="massive-view" title="Προβολή προόδου μετρήσεων" size="xl" hide-footer  @close="showMassiveView = false">
          <b-overlay :show="!massiveViewLoaded" rounded="sm">
            <div v-if="!massiveViewLoaded" style="height:300px"></div>
          </b-overlay>
          <div v-if="showMassiveView" class="col-md-12">
            <b-tabs v-if="massiveViewLoaded && displayedMeasurements" >
              <b-tab v-for="(category, index) of measurementCategories" :key="'category-' + category"
                     :title="measurementCategoryTitles[index]" class="measurement-tab-content">
                  <div v-for="measurement in measurements.filter(x => x.type === category && displayedMeasurements.includes(x.id))" :key="measurement.id" class="mb-2 note-body">
                    <div class="measurement-line row align-items-center ">

                      <div class="col-md-2 name">
                          <strong>{{measurement.name}}</strong>
                      </div>
                      <div class="col-md-10" style="padding-top:1em;">
                        <div class="row">
                          <div v-for="date in bulkMeasurements[category].dates" :key="'date-'+date" class="col-md-2 text-center value">
                            <div class="text-muted date-muted"> {{date}}</div>
                            <div class="value-number">
                          {{ findMeasurement(category, date, measurement.id) !== '-' ? findMeasurement(category, date, measurement.id) + ' ' + measurement.measure : '-' }}
                        </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr>
                  </div>
              </b-tab>
            </b-tabs>
          </div>
        </b-modal>


      <b-modal id="import-from-csv" ref="import-from-csv" size="xl" :title="$t('components.athlete.measures.importCsv')" hide-footer
               :no-close-on-backdrop="!isEducational">

        <measurements-csv-import v-if="!isEducational" :measurements="measurements"
            @refetchMeasurements="()=>bulkGetMeasurements(true)"
            @closeModal="() => { $refs['import-from-csv'].hide()}"
        ></measurements-csv-import>
        <educational-unavailable v-else/>
      </b-modal>
      <b-modal id="measurements-print-window" ref="print-window" title="Εκτύπωση μετρήσεων" size="xl" hide-footer  @close="showMassiveView = false">
        <b-overlay :show="!massiveViewLoaded" rounded="sm">
          <div v-if="!massiveViewLoaded" style="height:300px"></div>
        </b-overlay>
        <print-athlete-measurements-modal v-if="showMassiveView" :measurements="measurements"
                                          :displayedMeasurements="displayedMeasurements"
                                          :measurement-categories="measurementCategories"
                                          :measurement-category-titles="measurementCategoryTitles"
                                          :bulk-measurements="bulkMeasurements"/>

      </b-modal>

      <b-modal id="fat-meter-print-window" ref="fat-meter-print-window" title="Εκτύπωση λιπομετρητή" size="xl" hide-footer @close="showMassiveView = false">
        <b-overlay :show="!massiveViewLoaded" rounded="sm">
          <div v-if="!massiveViewLoaded" style="height:300px"></div>
        </b-overlay>
        <fat-meter-print-modal v-if="!isEducational" :measurements="measurements" :athlete-measurements="athleteMeasurements" :athlete="athlete"/>
        <educational-unavailable v-else/>

      </b-modal>

      <b-modal id="measurement-settings" ref="measurement-settings" title="Ρυθμίσεις μετρήσεων" size="xl" hide-footer>
        <measurement-settings :measurements="measurements" :displayed-measurements="displayedMeasurements" :measurement-categories="measurementCategories"
        :measurement-category-titles="measurementCategoryTitles"
        @displayedMeasurementChanged="displayedMeasurementsChanged"/>
      </b-modal>
      <b-tabs v-model="measurementsTabId" pills justified vertical class="questionnaire-tabs flex-nowrap" nav-class="nav-tabs nav-bordered pt-3">
            <b-tab title="Γενικές σημειώσεις">
                <div v-if="measurementsTabId === 0">
                    <b-card>
                        <b-card-title>
                            Γενικές σημειώσεις
                        </b-card-title>
                        <b-card-sub-title>Πληκτρολογήστε σημειώσεις για τις μετρήσεις του πελάτη σας ώστε να μην τις ξεχάσετε!</b-card-sub-title>
                        <b-card-body>
                            <quill-editor
                                    ref="noteEditor"
                                    v-model="athlete.measurementNotes"
                                    :options="quillOptions"
                                    @change="notesChanged = true"/>

                            <div class="text-right mt-2">
                              <save-button @click="updateGlobalNotes" :disabled="!notesChanged"></save-button>
                            </div>
                         </b-card-body>
                    </b-card>
                </div>
            </b-tab>
            <b-tab title="Προβολή προόδου μετρήσεων" @click="bulkGetMeasurements">
                <athlete-measurements-list v-if="measurementsTabId === 1" :athlete-id="athleteId"
                                           :athlete-measurements="athleteMeasurements"
                                           :measurements="measurements" :displayed-measurements="displayedMeasurements"
                                           :populated-measurements="populatedMeasurements"></athlete-measurements-list>
            </b-tab>

            <b-tab v-for="(category,index) in measurementCategories" :key="category + index" :title="measurementCategoryTitles[index]">
                <athlete-measurements-content v-if="measurementsTabId === index+2"
                                              :athlete-id="athleteId"
                                              :measurements="measurements"
                                              :populated-measurements="populatedMeasurements"
                                              :displayed-measurements="displayedMeasurements"
                                              :type1="category"
                                              @measurementsChanged="resetBulkMeasurements"></athlete-measurements-content>
            </b-tab>
        </b-tabs>


    </div>

    <!-- END MEASUREMENT DATA AND DIAGRAM -->
</template>

