<template>
  <div class="print-text-center">
    <div class="print-measurement-label">
      {{ label }}
    </div>
    <div class="print-measurement-result">
      {{getValue}}
    </div>
  </div>
</template>

<script>
export default{
  props:{
    label: String,
    value: [String, Number]
  },

  computed:{
    getValue(){
      if(!this.value) return '-';
      if(this.value.toString().includes('-') || this.value.toString().includes('undefined') || this.value.toString().includes('null')){
        return '-'
      }
      return this.value;
    }
  }
}
</script>