<template>
  <div class="print-items-center" :style="`display:flex;`">
    <div class="print-rounded "
         :style="`margin-right:12px; background-color:${colors.primary};color:white;width:fit-content;`">
      <div v-html="icon" class="print-icon"></div>

    </div>
    <div>
      <div
           style="font-weight:700;font-size:12px;"
           :style="`color:${colors.primary}`">{{ label }}</div>
      <div class="print-regular-text">{{ getValue }}</div>
    </div>
  </div>
</template>

<script>
export default{

  props:{
    colors: Object,
    value: String,
    label: String,
    icon: String
  },
  computed:{

    getValue(){
      if(!this.value || !this.value.toString()) return '-';
      if(this.value.includes('undefined') || this.value.includes('null')) return '-';

      return this.value;
    }
  }
}
</script>