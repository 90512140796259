<script>
    import AthleteProfile from "./athleteProfile";
    import AthleteMeasurements from "./measurements/athleteMeasurements"
    import AthleteQuestionnaire from "@components/athletes/athleteQuestionnaire"
    import AthleteProgressList from "./athleteProgressList"
    import dietPlanList from "@views/dietPlan/list/dietPlanList";
    import athleteExercises from "@components/exercises";
    import AthleteMobileApp from "./athleteMobileApp"
    import athleteFiles from "@views/athletes/athlete/athleteFiles";

    export default {

        page: {title: 'Επισκόπηση πελάτη'},
        components: {AthleteMeasurements, AthleteProfile, AthleteQuestionnaire, dietPlanList, athleteExercises, AthleteProgressList, AthleteMobileApp, athleteFiles},

        data(){
            return {
                athlete: null,
                athleteTabId: 0,
                tags: [],
                autocompleteItems: [],
                athleteId: this.$route.params.athleteId,
                tabs: ['edit-profile','progress-list', 'dietplans', 'measurements', 'supplements', 'questionnaire', 'mobile-app', 'files'],
                isSportsListLoaded: false,
                sports: [],
                tabsKey: 0
            }
        },

      watch:{
          '$route.params.athleteId'(val){
            this.athleteId = val;
            // re-fetch
            this.getAthlete(true);
            this.fetchCurrentAthleteTags();
          }
      },
        mounted() {
            // Get the previous tab from the url
          this.$nextTick(()=>{
            this.athleteTabId = this.tabs.findIndex(tab => '#'+tab === this.$route.hash);
          })

        },

        async created() {
            await this.fetchUserAthleteTags();
            this.autocompleteItems = this.$store.state.tags._athleteTags;
            if(!this.autocompleteItems){
                this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των ετικετών απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
            }
            await this.fetchSports();

            this.getAthlete(false);
            this.fetchCurrentAthleteTags();


        },

        methods: {
            addHashToLocation() {
                let tabHash = this.tabs[this.athleteTabId];
                history.pushState(
                    {},
                    null,
                    this.$route.path + '#' + tabHash
                )
            },

            getAthlete(reRenderTabs){

                this.$axios.get(`/athlete/${this.athleteId}`).then( result => {
                    this.athlete = result.data;
                  this.$store.commit('ui/setActiveItem', {
                    value: this.athlete.firstName + ' ' +  this.athlete.lastName,
                    index: 0
                  });
                  localStorage.setItem('widget.customer_email', this.athlete.email);

                  if(reRenderTabs) this.tabsKey++;


                }).catch(e => {
                    if(e.response && e.response.status === 403){
                        this.$router.push('/athletes');
                    }
                    this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των δεδομένων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },

            async fetchUserAthleteTags(){
                if(!this.$store.state.tags._athleteTags) {
                    await this.$store.dispatch('tags/fetchCacheAthleteTags');
                }else{
                    await this.$store.dispatch('tags/refreshAthleteTagsCache');
                }
            },

            fetchCurrentAthleteTags(){
                this.$axios.get(`/athlete/${this.athleteId}/tag`).then(result => {
                    this.tags = result.data;
                }).catch(e => {
                    this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των ετικετών απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },

            async fetchSports(){
                if(!this.$store.state.sports._sports) {
                    await this.$store.dispatch('sports/fetchCacheSports');
                }else{
                    await this.$store.dispatch('sports/refreshCacheSports');
                }

                this.isSportsListLoaded = true;
                this.sports = this.$store.state.sports._sports;
            },
        }
    }

</script>

<style lang="scss">
    @import '@src/design/custom/pages/_athlete.scss';
</style>
<template>
    <div id="athlete-base-layout">

        <b-tabs :key="tabsKey" v-model="athleteTabId" pills justified class="navtab-bg athlete-tabs" @input="addHashToLocation">
            <b-tab>
                <template v-slot:title>
                    <div class="tab-icon"><i class="icon-user"></i></div>
                    <div class="break"></div>
                    <div class="tab-title">Στοιχεία</div>
                    <div class="break"></div>
                    <div class="tab-subtitle">
                        Βασικές πληροφορίες επικοινωνίας
                    </div>
                </template>
                <athlete-profile v-if="athleteTabId === 0 && athlete && tags" :athlete.sync="athlete" :autocomplete-items="autocompleteItems" :tags="tags"></athlete-profile>
            </b-tab>
            <b-tab>
                <template v-slot:title>
                    <div class="tab-icon"><i class="icon-graph"></i></div>
                    <div class="break"></div>
                    <div class="tab-title"> Φύλλο παρακολούθησης</div>
                    <div class="break"></div>
                    <div class="tab-subtitle">
                        Καταγραφή της προόδου ανά ραντεβού
                    </div>
                </template>
                <athlete-progress-list v-if="athleteTabId === 1 && athlete" :athlete="athlete"></athlete-progress-list>
            </b-tab>
            <b-tab>
                <template v-slot:title><div class="tab-icon"><i class="icon-calender"></i></div>
                    <div class="break"></div>
                    <div class="tab-title">Διατροφικά Πλάνα</div>
                <div class="break"></div>
                <div class="tab-subtitle">
                    Εβδομαδιαία προγράμματα διατροφής
                </div></template>
                <diet-plan-list :v-if="athleteTabId === 2 && athlete" :athlete-view="true" :athlete-id="athleteId"></diet-plan-list>
            </b-tab>
            <b-tab >
                <template v-slot:title><div class="tab-icon"><i class="icon-chemistry"></i></div> <div class="break"></div> <div class="tab-title">Μετρήσεις</div>
                    <div class="break"></div>
                    <div class="tab-subtitle">
                        Σωματομετρικές, βιταμίνες, αιματολογικές...
                    </div></template>
                <athlete-measurements v-if="athleteTabId === 3 && athlete" :athlete="athlete"></athlete-measurements>
            </b-tab>

            <b-tab>
                <template v-slot:title>
                <div class="tab-icon"><i class="icon-energy"></i></div> <div class="break"></div> <div class="tab-title">Άσκηση & Συμπληρώματα</div>
                <div class="break"></div>
                <div class="tab-subtitle">
                    Άσκηση και συνιστώμενα συμπληρώματα
                </div>
            </template>
                <div v-if="athleteTabId === 4 && athlete && isSportsListLoaded" class="card">
                    <athlete-exercises  :is-athlete="true" :athlete-id="athlete.id" :sports="sports"></athlete-exercises>
                </div>
            </b-tab>
            <b-tab>
                <template v-slot:title><div class="tab-icon"><i class="icon-question"></i></div><div class="break"></div><div class="tab-title">Ερωτηματολόγιο</div>
                    <div class="break"></div>
                    <div class="tab-subtitle">
                        Σύντομο καθώς και αναλυτικό ερωτηματολόγιο
                    </div>
                </template>
                <athlete-questionnaire v-if="athleteTabId === 5 && athlete" :athlete="athlete"></athlete-questionnaire>
            </b-tab>
            <b-tab>
                <template v-slot:title><div class="tab-icon"><i class="icon-screen-smartphone"></i></div><div class="break"></div><div class="tab-title">Mobile Εφαρμογή</div>
                    <div class="break"></div>
                    <div class="tab-subtitle">
                        Ημερολόγιο καταγραφής - στόχοι
                    </div>
                </template>
                <athlete-mobile-app v-if="athleteTabId === 6 && athlete" :athlete="athlete"></athlete-mobile-app>
            </b-tab>

          <b-tab>
            <template v-slot:title><div class="tab-icon"><i class="icon-docs"></i></div><div class="break"></div><div class="tab-title">Αρχεία</div>
              <div class="break"></div>
              <div class="tab-subtitle">
                Λίστα αποθηκευμένων αρχείων πελάτη
              </div>
            </template>
            <athlete-files v-if="athleteTabId === 7 && athlete" :athlete="athlete"/>
          </b-tab>

        </b-tabs>
    </div>

</template>
