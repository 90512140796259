<template>

    <div>
            <b-button variant="primary" size="xs" @click="$emit('closeModal')"><i class="icon-close"/> Κλείσιμο</b-button>

        <chrome-picker :value="value" @input="colorChanged"></chrome-picker>

    </div>
</template>


<script>

    import {Chrome} from "vue-color";

    export default{
        components: {'chrome-picker': Chrome},

        props:['value'],

        methods:{
            colorChanged(color){
                this.$emit('colorChanged', color.hex8)
            }
        }
    }
</script>
