<template>

  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Πρόοδος μετρήσεων</h4>
      <h6 class="card-subtitle text-muted">Δείτε συγκεντρωτικά την πρόοδο των μετρήσεων του πελάτη σας</h6>

      <div class="card-content pt-3">

        <label class="font-weight-bold">Επιλέξτε μετρήσεις (ενεργές εμφανίζονται όσες έχετε περασμένες μετρήσεις)</label>
        <div class="d-flex flex-wrap gap-2" >
          <button v-for="measurement in getPossibleMeasurements" :key="measurement.id"
                    class="list-selector cursor-pointer d-flex align-items-center"
               :class="`${selectedMeasurements.includes(measurement.id) ? 'selected' : ''}`"
                  :disabled="!hasMeasurement(measurement.id)"
          @click="toggleMeasurement(measurement.id)">

            <div class="border border-primary rounded-full mr-2 my-0 py-0 d-flex align-items-center justify-content-center text-center flex-shrink-0"
                 style="width:15px;height:15px;border-radius:100%;"
                 :class="selectedMeasurements.includes(measurement.id) ? 'bg-primary' : ''">
              <svg v-if="selectedMeasurements.includes(measurement.id)" width="10" height="10"
                   viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0303 7.96967C18.3232 8.26256 18.3232 8.73744 18.0303 9.03033L11.0303 16.0303C10.7374 16.3232 10.2626 16.3232 9.96967 16.0303L5.96967 12.0303C5.67678 11.7374 5.67678 11.2626 5.96967 10.9697C6.26256 10.6768 6.73744 10.6768 7.03033 10.9697L10.5 14.4393L16.9697 7.96967C17.2626 7.67678 17.7374 7.67678 18.0303 7.96967Z" fill="white"/>
              </svg>
            </div>

            {{measurement.name}}
          </button>
        </div>



        <div class="py-2">
          <apexchart :key="chartKey" height="400px" type="line" :options="options" :series="series"></apexchart>
        </div>


      </div>

    </div>

  </div>

</template>

<script>
import moment from "moment";

export default{
  components: {},
  props:{
    athleteId:{
      default: null,
      type: String
    },
    athleteMeasurements:{
      default: () => { return []},
      type: Array
    },
    measurements: {
      default: () => { return []},
      type: Array
    },
    populatedMeasurements:{
      default: () => {return []},
      type:Array
    },
    displayedMeasurements: Array
  },

  computed:{

    getPossibleMeasurements(){
      return this.measurements.filter(x => {
        return Object.values(this.possibleMeasurements).includes(x.id)
      })
    },
    options() {
      return {
        chart: {
          id: 'measurement',
              toolbar: {
            show: false,
                tools: {
              download: false,
                  selection: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  pan: false,
                  reset:false,
                  customIcons: []
            },
          },

        },
        stroke: {
          width: 2,
              curve: 'smooth'
        },

        markers: {
          size: 3,
              opacity: 1,
              colors: ['#3155A6', '#EE9DBF', '#16a34a', '#7e22ce', '#b91c1c', '#db2777', '#facc15', '#22d3ee'],
              strokeColor: '#fff',
              strokeWidth: 1,
              style: 'inverted', // full, hollow, inverted
              hover: {
            size: 6,
          }
        },
        xaxis: {
          categories: this.labels
        },
        yaxis: {
          forceNiceScale: true,
              labels:{
            formatter: (val,opts) => {
              let measure = '';
              try{
                measure = opts.w.config.series[opts.seriesIndex].unit || '';
              }catch(e){}
              return (val||'-') + ' ' + (measure) }
          }
        },
        colors:['#3155A6', '#EE9DBF', '#16a34a', '#7e22ce', '#b91c1c', '#db2777', '#facc15', '#22d3ee']
      }
    },
  },
  watch:{
    series(){
      this.chartKey++;
    },
    athleteMeasurements(){
      this.prepareSeries();
    }
  },
  data(){
    return{
      series: [],
      labels: [],
      selectedMeasurements: [2,3,101,102],
      possibleMeasurements: {
        weight: 2,
        fatPer: 3,
        muscleMass: 101,
        organsFat: 104,
        fatKg: 106,
        bodyWaterPer: 102,
        fatlessMass: 107,
        skeletalMass: 127
      },
      chartKey: 0,

    }

  },
  mounted(){
    this.prepareSeries();
  },
  methods:{
    hasMeasurement(id){
      return this.athleteMeasurements?.filter(x => x.measurementsId === id)?.map(x => x.value).length > 0
    },
    toggleMeasurement(id){

      if(this.selectedMeasurements.includes(id)){
        this.selectedMeasurements = this.selectedMeasurements.filter(x => x !== id);
      }else{
        this.selectedMeasurements.push(id);
      }
      this.prepareSeries();
    },

    prepareSeries(){
      let series = [];
      let dates = new Set();
      if(!this.selectedMeasurements || !this.measurements || !this.athleteMeasurements) return [];

      for(let measurement of this.selectedMeasurements) {
        let values = this.athleteMeasurements.filter(x => x.measurementsId === measurement).map(x => x.measurementDate);
        for(let value of values){
          dates.add(value);
        }
      }

      dates = Array.from(dates).reverse();

      for(let measurement of this.selectedMeasurements){
        if(!this.hasMeasurement(measurement)) continue;
        let measurements = [];
        let values = this.athleteMeasurements.filter(x => x.measurementsId === measurement).map(x => {
          return {
            value: x.value,
            date: x.measurementDate
          }
        });

        // parse all dates and get appropriate values.
        for(let date of dates){
          if(values.find(x => x.date === date)) measurements.push(values.find(x => x.date === date));
        }
        // push series.
        series.push({
          name: this.measurements.find(x => x.id === measurement).name,
          data: measurements.map(x => x.value),
          unit: this.measurements.find(x => x.id === measurement).measure
        })
      }
      this.labels = dates;
      this.series = series;
    }
  }
}
</script>

<style lang="scss">
</style>